import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Database, Tables } from "@/lib/database.types";
import { useDialogHandler } from "@/src/hooks/use-dialog-handler";
import PageLayout from "@/src/layouts/PageLayout";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

interface Memorabilia extends Tables<"souvenir"> {
  sizes: Tables<"souvenir_size">[];
}

const OrderMemorabiliaPage = () => {
  const user = useUser();

  const supabase = useSupabaseClient<Database>();

  const { isDialogOpen, onOpenChange, onClose, onOpen } = useDialogHandler();

  const [souvenirs, setSouvenirs] = useState<Memorabilia[]>([]);
  const [selectedShirts, setSelectedShirts] = useState<{
    [id: string]: number;
  }>({});
  const [cartItems, setCartItems] = useState<{
    [id: string]: number;
  }>({});

  useEffect(() => {
    const fetchMeals = async () => {
      const [
        { data: souvenirData },
        { data: _souvenirs },
        { data: cartItems },
      ] = await Promise.all([
        supabase.from("souvenir").select("*"),
        supabase.from("souvenir_size").select("*"),
        supabase
          .from("souvenir_order_item")
          .select("*, order_id!inner(status, user_id)")
          .eq("order_id.status", "IN_CART")
          .eq("order_id.user_id", user?.id!),
      ]);

      console.log(cartItems);

      const cart =
        cartItems?.reduce(
          (acc, item) => ({
            ...acc,
            [item.product_id]: item.quantity,
          }),
          {}
        ) || {};

      setCartItems(cart);

      setSelectedShirts(cart);

      if (souvenirData) {
        const souvenirDataMap = new Map<
          string,
          {
            sizes: Tables<"souvenir_size">[];
          } & Tables<"souvenir">
        >();

        souvenirData.forEach((souvenir) => {
          const souvenirSize = _souvenirs?.filter(
            (s) => s.souvenir_id === souvenir.id
          );

          if (souvenirSize) {
            souvenirDataMap.set(souvenir.id, {
              ...souvenir,
              sizes: souvenirSize,
            });
          }
        });

        setSouvenirs(Array.from(souvenirDataMap.values()));
      }
    };

    if (user) fetchMeals();
  }, [supabase, user]);

  const handleQuantityChange = (id: string, quantity: number) => {
    setSelectedShirts((prevShirts) => ({ ...prevShirts, [id]: quantity }));
  };

  const calculateTotalPrice = (
    type: Tables<"souvenir_size">,
    isCart = false
  ) => {
    return (
      type.price! *
      ((isCart ? cartItems[type.id] : selectedShirts[type.id]) || 0)
    );
  };

  async function handleAddToCart() {
    if (!user) return toast.error("You must be logged in to place an order");

    let orderId = "";

    const { data: previousOrder } = await supabase
      .from("souvenir_order")
      .select("*")
      .eq("user_id", user.id)
      .eq("status", "IN_CART")
      .maybeSingle();

    orderId = previousOrder?.id!;

    if (!previousOrder) {
      const { data, error } = await supabase
        .from("souvenir_order")
        .insert({
          user_id: user.id,
          status: "IN_CART",
        })
        .select("*")
        .maybeSingle();

      if (error) return toast.error(error.message);

      orderId = data?.id!;
    }

    const orderItems = Object.entries(selectedShirts).map(
      ([size_id, quantity]) => ({
        size_id,
        quantity,
      })
    );

    const { data: orderItemsData, error: orderItemsError } = await supabase
      .from("souvenir_order_item")
      .select("*")
      .eq("order_id", orderId);

    if (orderItemsError) return toast.error(orderItemsError.message);

    const existingOrderItems = orderItemsData || [];

    const existingOrderItemsMap = new Map(
      existingOrderItems.map((item) => [item.product_id, item])
    );

    let orderedItems: {
      updated: Tables<"souvenir_order_item">[];
      inserted: unknown[];
      delete: Tables<"souvenir_order_item">[];
    } = {
      updated: [],
      inserted: [],
      delete: [],
    };

    orderItems.map((item) => {
      const existingItem = existingOrderItemsMap.get(item.size_id);

      if (item.quantity === 0) {
        if (existingItem) orderedItems.delete.push(existingItem);

        return;
      }

      if (existingItem) {
        const updatedItem = {
          ...existingItem,
          quantity: item.quantity,
        };

        orderedItems.updated.push(updatedItem);

        return;
      }

      const insertedItem = {
        order_id: orderId,
        product_id: item.size_id,
        quantity: item.quantity,
      };

      orderedItems.inserted.push(insertedItem);
    });

    console.log(orderedItems);

    const [{ error: error1 }, { error: error2 }] = await Promise.all([
      await supabase.from("souvenir_order_item").upsert(orderedItems.updated),
      await supabase
        .from("souvenir_order_item")
        .insert(orderedItems.inserted as Tables<"souvenir_order_item">[]),
      await supabase
        .from("souvenir_order_item")
        .delete()
        .in(
          "id",
          orderedItems.delete.map((item) => item.id)
        ),
    ]);

    if (error1 || error2)
      return toast.error(error1?.message || error2?.message);

    setCartItems(
      Object.entries(selectedShirts)
        .filter(([_, quantity]) => quantity > 0)
        .reduce(
          (acc, [id, quantity]) => ({
            ...acc,
            [id]: quantity,
          }),
          {}
        )
    );

    toast.success(
      "Cart updated successfully. Click on 'View Cart' to see the items in your cart."
    );
  }

  async function handleOrder() {
    if (!user) return toast.error("You must be logged in to place an order");

    const { data: order } = await supabase
      .from("souvenir_order")
      .select("*")
      .eq("user_id", user.id)
      .eq("status", "IN_CART")
      .maybeSingle();

    if (!order) return toast.error("No items in cart");

    const { error } = await supabase
      .from("souvenir_order")
      .update({ status: "PENDING", placed: new Date().toISOString() })
      .eq("id", order.id);

    if (error) return toast.error(error.message);

    setCartItems({});
    setSelectedShirts({});

    onClose();

    toast.success("Order placed successfully");
  }

  return (
    <PageLayout className="p-4 space-y-10  pb-20">
      {souvenirs.map((memorabilia) => (
        <fieldset key={memorabilia.id} className="border rounded-lg md:flex">
          <legend className="md:hidden px-1 mx-4 text-2xl font-bold">
            {memorabilia.name}
          </legend>

          <img
            className="p-4 w-full max-w-sm"
            src={memorabilia.image_path!}
            alt={memorabilia.name}
          />

          <div className="w-full divide-y md:border-l">
            <legend className="hidden md:block text-2xl font-bold px-4 py-2">
              {memorabilia.name}
            </legend>

            {memorabilia.sizes.map((type) => (
              <div key={type.id} className="flex justify-between px-4 py-2">
                <span>{type.size}</span>

                <div className="flex items-center space-x-4">
                  <div className="w-12 text-right">
                    <span className="">${type.price?.toFixed(2)}</span>
                  </div>

                  <span>x</span>

                  <input
                    className="w-10 text-center"
                    type="number"
                    value={selectedShirts[type.id] || 0}
                    onChange={(e) =>
                      handleQuantityChange(
                        type.id,
                        parseInt(e.target.value, 10)
                      )
                    }
                    min={0}
                  />

                  <span>{"="}</span>

                  <span className="w-20 text-right">
                    ${calculateTotalPrice(type).toFixed(2)}
                  </span>
                </div>
              </div>
            ))}

            <div className="flex items-center justify-between">
              <p className="px-4 py-2">Total Price:</p>

              <p className="px-4 py-2">
                $
                {memorabilia.sizes
                  .reduce((acc, type) => acc + calculateTotalPrice(type), 0)
                  .toFixed(2)}
              </p>
            </div>
          </div>
        </fieldset>
      ))}

      <div className="border-t fixed left-0 bottom-0 p-2 bg-background w-full flex items-center justify-between">
        <h4 className="font-inter not-italic">
          Total Price: $
          {Object.keys(selectedShirts)
            .reduce((acc, id) => {
              const memorabilia = souvenirs.find((m) =>
                m.sizes.find((t) => t.id === id)
              );
              if (memorabilia) {
                acc +=
                  memorabilia.sizes.find((t) => t.id === id)!.price! *
                  selectedShirts[id];
              }
              return acc;
            }, 0)
            .toFixed(2)}
        </h4>

        <div className="space-x-2">
          <Button
            onClick={handleAddToCart}
            variant="white"
            disabled={selectedShirts === cartItems}
          >
            Update Cart
          </Button>

          <Button className="relative" onClick={onOpen}>
            {Object.keys(cartItems).length !== 0 && (
              <span className="w-5 h-5 grid place-content-center bg-red-500 rounded-full">
                {Object.keys(cartItems).length}
              </span>
            )}
            View Cart
          </Button>
        </div>
      </div>

      <Sheet open={isDialogOpen} onOpenChange={onOpenChange}>
        <SheetContent className="w-full !max-w-xl min-h-screen overflow-auto">
          <SheetHeader>
            <SheetTitle className="text-2xl">Memorabilia Order</SheetTitle>
          </SheetHeader>

          {Object.entries(cartItems).length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            <>
              <div className="mt-4 flex flex-col gap-2">
                <div className="font-semibold flex justify-between">
                  <div className="space-x-2">
                    <span className="w-10">Image</span>
                    <span>Name</span>
                  </div>

                  <div className="flex items-center space-x-2">
                    <span>Qty</span>

                    <span className="w-20 text-right">Price</span>
                  </div>
                </div>

                {Object.entries(cartItems).map(([id, quantity]) => {
                  const memorabilia = souvenirs.find((m) =>
                    m.sizes.find((t) => t.id === id)
                  );
                  const type = memorabilia?.sizes.find((t) => t.id === id);

                  return (
                    <div key={id} className="flex justify-between">
                      <div className="flex flex-wrap items-center gap-4">
                        <img
                          src={`${
                            import.meta.env.NEXT_PUBLIC_SUPABASE_URL
                          }/storage/v1/object/public/${
                            memorabilia?.image_path
                          }`}
                          alt={memorabilia?.name}
                          className="w-10 h-10 object-cover rounded-lg"
                        />

                        <span>
                          {memorabilia?.name} - {type?.size}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <span>x {quantity}</span>

                        <span className="w-20 text-right">
                          ${calculateTotalPrice(type!, true).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  );
                })}

                <hr />

                <div className="flex items-center justify-between">
                  <p>Total Price:</p>

                  <p>
                    $
                    {Object.keys(cartItems)
                      .reduce((acc, id) => {
                        const memorabilia = souvenirs.find((m) =>
                          m.sizes.find((t) => t.id === id)
                        );
                        if (memorabilia) {
                          acc +=
                            memorabilia.sizes.find((t) => t.id === id)!.price! *
                            cartItems[id];
                        }
                        return acc;
                      }, 0)
                      .toFixed(2)}
                  </p>
                </div>

                <Button onClick={handleOrder} className="mt-4 w-full">
                  Place Order
                </Button>
              </div>
            </>
          )}
        </SheetContent>
      </Sheet>
    </PageLayout>
  );
};

export default OrderMemorabiliaPage;
