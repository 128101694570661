import {Button} from "@/components/ui/button";
import {Form} from "@/components/ui/form";
import {InputFormField} from "@/components/ui/input/input-form-field";
import {zodResolver} from "@hookform/resolvers/zod";
import {ArrowLeft, Lock} from "lucide-react";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {z} from "zod";
import EnquireSection from "../components/EnquireSection";
import {Svgs} from "../components/svgs";
import PageLayout from "../layouts/PageLayout";
import {useSupabaseClient} from "@supabase/auth-helpers-react";
import {toast} from "sonner";

const resetPasswordFormSchema = z.object({
  new_password: z.string().min(8, {
    message: "Password must be at least 8 characters",
  }),
  confirm_password: z.string().min(8, {
    message: "Password must be at least 8 characters",
  }),
});

const ResetPassword = () => {
  const supabase = useSupabaseClient()

  const resetPasswordForm = useForm<z.infer<typeof resetPasswordFormSchema>>({
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues: {
      confirm_password: "",
      new_password: "",
    },
  });

  async function handleResetPassword(
    formData: z.infer<typeof resetPasswordFormSchema>
  ) {
    if (formData.new_password != formData.confirm_password){
      toast.error("Passwords must match!")
      return
    }
    const {error} = await supabase.auth
      .updateUser({password: formData.new_password})
    if (error) {
      toast("There was an error updating your password.")
      console.error(error)
    } else {
      toast("Password updated successfully!")
    }
  }

  return (
    <PageLayout className="p-10 items-center">
      <div className="border p-8 max-w-lg w-full m-auto rounded-sm space-y-4">
        <Link
          to="/login"
          className="text-sm text-primary-500 flex items-center gap-1"
        >
          <ArrowLeft className="w-5 h-5"/>
          Back
        </Link>

        <div className="w-full flex items-center gap-8">
          <h3 className="flex-shrink-0">Reset Password</h3>

          <Svgs.title_leaf className="w-full h-full mt-1"/>
        </div>

        <Form {...resetPasswordForm}>
          <form
            onSubmit={resetPasswordForm.handleSubmit(handleResetPassword)}
            className="grid gap-4 "
          >
            <InputFormField
              Icon={Lock}
              label="New Password"
              type="password"
              name="new_password"
              placeholder="*****************"
              required
            />

            <InputFormField
              Icon={Lock}
              label="Confirm New Password"
              type="password"
              name="confirm_password"
              placeholder="*****************"
              required
            />

            <Button size="lg">Reset</Button>
          </form>
        </Form>
      </div>

      <EnquireSection/>
    </PageLayout>
  );
};

export default ResetPassword;
