import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input/index";
import { Database } from "@/lib/database.types";
import { cn } from "@/lib/utils";
import { DataTable } from "@/src/components/DataTable";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { ColumnDef } from "@tanstack/react-table";
import { SearchIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useDialogHandler } from "../../hooks/use-dialog-handler";
import PageLayout from "../../layouts/PageLayout";

const CoreCastPage = () => {
  const supabase = useSupabaseClient<Database>();

  const { isDialogOpen, onOpenChange } = useDialogHandler();

  const [copiedEmail, setCopiedEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [applications, setApplications] = useState<
    Database["public"]["Tables"]["application"]["Row"][]
  >([]);

  useEffect(() => {
    async function fetchApplicants() {
      setIsLoading(true);

      const filter = supabase
        .from("group_cast")
        .select("*, application_availability(application:application_id(*))")
        .eq("group_type", "CORE_CAST");

      const { data: groupCastData, error } = await filter;

      if (error) console.error("Error fetching application", error);

      if (groupCastData) {
        const applicationMap = new Map();

        groupCastData.forEach((groupCast) => {
          groupCast.application_availability.forEach(({ application }: any) => {
            applicationMap.set(application.id, application);
          });
        });

        const applications = Array.from(applicationMap.values());

        setApplications(applications);
      }

      setIsLoading(false);
    }

    fetchApplicants();
  }, [supabase]);

  useEffect(() => {
    const timeoutFunc = setTimeout(() => {
      setCopiedEmail("");
    }, 2000);

    return () => {
      clearTimeout(timeoutFunc);
    };
  }, [copiedEmail]);

  const columns: ColumnDef<
    Database["public"]["Tables"]["application"]["Row"]
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
    },
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) =>
        row.getValue("id") && (
          <span className="block w-20 truncate">{row.getValue("id")}</span>
        ),
    },
    {
      accessorKey: "name",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span>CORE CAST / Directing Staff</span>
          <span className="bg-amber-400">Name</span>
        </div>
      ),
    },
    {
      accessorKey: "photo",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="bg-amber-400">Photo</span>
        </div>
      ),
      cell: () => {
        return <div className=""></div>;
      },
    },
    {
      accessorKey: "Services",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Services</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Volunteer",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Volunteer</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Travel",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Travel</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "empty",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90"></p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Meals",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Meals</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Insurance",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Insurance</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Medical",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Medical</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Image",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Image</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Liability",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Liability</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Payee Setup",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">Payee Setup</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "W-9",
      header: () => (
        <div className="h-28 w-full bg-indigo-200 grid family-cast-table-header">
          <span className="grid place-content-center">
            <p className="text-sm -rotate-90">W-9</p>
          </span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "NauvooRole",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="bg-amber-400">Nauvoo / British Role</span>
        </div>
      ),
    },
    {
      accessorKey: "PhoneAddress",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="bg-amber-400">Phone / Address</span>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="w-80 whitespace-nowrap overflow-x-auto">
            <div className="pb-2">{row.getValue("PhoneAddress")}</div>
          </div>
        );
      },
    },
    {
      accessorKey: "notification_emails",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="bg-amber-400">Email</span>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="grid">
            {row.original?.notification_emails?.map((email) => (
              <span
                key={email}
                className={cn(
                  "px-2 py-1 w-fit relative text-blue-600 cursor-pointer underline",
                  copiedEmail === `${row.original}-${email}` && "text-black"
                )}
                onClick={() => {
                  setCopiedEmail(`${row.original}-${email}`);

                  navigator.clipboard.writeText(email);
                }}
              >
                {email}

                {copiedEmail === `${row.original}-${email}` && (
                  <span className="top-0 left-0 absolute w-full h-full bg-green-500/90 rounded-md font-semibold text-center grid place-content-center">
                    Copied
                  </span>
                )}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "Flying",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">Flying</span>
          <span className="bg-amber-100 w-full h-full">Flying</span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Driving",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">Driving</span>
          <span className="bg-amber-100 w-full h-full">Driving</span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Meals",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">Meals</span>
          <span className="bg-amber-100 w-full h-full">Meals</span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Hon. Paid?",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">Hon. Paid?</span>
          <span className="bg-amber-100 w-full h-full">Hon. Paid?</span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Reimbursed?",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">Reimbursed?</span>
          <span className="bg-amber-100 w-full h-full">Reimbursed?</span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
    {
      accessorKey: "Reimb.Amt?",
      header: () => (
        <div className="h-28 grid family-cast-table-header">
          <span className="text-transparent select-none">Reimb.Amt?</span>
          <span className="bg-amber-100 w-full h-full">Reimb.Amt?</span>
        </div>
      ),
      cell: () => {
        return <Checkbox onCheckedChange={() => {}} />;
      },
    },
  ];

  return (
    <PageLayout className="p-4 gap-8 family-cast-table" showFullLogo>
      <DataTable
        isLoading={isLoading}
        TopActionButtons={() => (
          <div className="relative flex items-center">
            <SearchIcon className="ml-2 absolute w-5 h-5 text-[#85807C]" />
            <Input placeholder="Search" className="h-9 bg-white pl-10" />
          </div>
        )}
        columns={columns}
        data={applications}
        hidePagination
      />

      <Dialog open={isDialogOpen} onOpenChange={onOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you absolutely sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove your data from our servers.
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </PageLayout>
  );
};

export default CoreCastPage
