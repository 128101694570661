import Markdown, { defaultUrlTransform, UrlTransform } from "react-markdown"
import Mustache from "mustache"
import { create } from "zustand"
import { persist, PersistStorage, StorageValue } from "zustand/middleware"
import superjson from "superjson"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { Database } from "@/lib/database.types.ts"
import { useEffect } from "react"
import { SupabaseClient } from "@supabase/supabase-js"
import { cn } from "@/lib/utils.ts"

//https://www.npmjs.com/package/react-markdown
//https://www.npmjs.com/package/mustache

class SuperJsonStorage<S> implements PersistStorage<S> {
  getItem = (name: string): StorageValue<S> | null => superjson.parse(localStorage.getItem(name) ?? '{"json":null}')
  setItem = (name: string, value: StorageValue<S>) => localStorage.setItem(name, superjson.stringify(value))
  removeItem = (name: string) => localStorage.removeItem(name)
}

type TemplateState = {
  lastUpdate: Date
  slugToTemplate: Map<string, string>
}

export const useTemplateStore = create<TemplateState>()(
  persist(
    () => ({
      lastUpdate: new Date(0),
      slugToTemplate: new Map(),
    }),
    {
      name: "template-storage",
      storage: new SuperJsonStorage<TemplateState>(),
    },
  ),
)

function useTemplate(slug: string) {
  const supabase = useSupabaseClient<Database>()
  const template = useTemplateStore((state) => state.slugToTemplate.get(slug))
  const lastUpdate = useTemplateStore((state) => state.lastUpdate)
  let templateCacheExpiryMilliseconds = Number(import.meta.env.VITE_TEMPLATE_CACHE_EXPIRY_SECONDS) * 1000
  if (import.meta.env.VITE_TEMPLATE_CACHE_EXPIRY_SECONDS == undefined) {
    console.error("VITE_TEMPLATE_CACHE_EXPIRY_SECONDS not defined")
    templateCacheExpiryMilliseconds = 0
  }

  //runs after every render
  useEffect(() => {
    let ignore = false
    // if cache is out of date, update non-blocking-ly
    if (new Date().getTime() - lastUpdate.getTime() < templateCacheExpiryMilliseconds) return
    console.log("templates possibly out of date, checking...")
    const transactionStart = new Date()
    const iife = async () => {
      const { data, error } = await supabase
        .from("template")
        .select("id, slug, content")
        .gte("updated_at", lastUpdate.toISOString())
      if (ignore) {
        return
      }
      if (error != null) {
        console.error(error.message)
        return
      }
      if (data == null) {
        console.error("New templates returned null instead of empty array?")
        return
      }
      const newEntries: [string, string][] = data.map(({ slug, content }) => [slug, content])
      useTemplateStore.setState(({ slugToTemplate }) => ({
        lastUpdate: transactionStart,
        slugToTemplate: new Map([...slugToTemplate.entries(), ...newEntries]),
      }))
      console.log("...updated templates")
    }
    iife()
    return () => {
      ignore = true
    }
  })
  return template
}

const urlTransform: UrlTransform = (url) => {
  if (url.length > 4 && url.startsWith("tel:") && !isNaN(parseInt(url.slice(4)))) {
    return url
  }
  return defaultUrlTransform(url)
}

type MarkdownComponents = Parameters<typeof Markdown>[0]["components"]

export default function Template(props: { slug: string; className?: string; markdownComponents?: MarkdownComponents }) {
  const DEBUG = import.meta.env.DEV && import.meta.env.VITE_HIGHLIGHT_TEMPLATES
  const template = useTemplate(props.slug)
  if (template == undefined) {
    return <p>loading...</p>
  }
  const rendered = Mustache.render(template, {})
  return (
    <div title={DEBUG ? props.slug : undefined}>
      <Markdown
        className={cn(DEBUG ? "bg-lime-300 space-y-4" : "space-y-4", props.className)}
        components={props.markdownComponents}
        urlTransform={urlTransform}
      >
        {rendered}
      </Markdown>
    </div>
  )
}

export async function forceRefreshTemplates(supabase: SupabaseClient) {
  const transactionStart = new Date()
  const { data, error } = await supabase.from("template").select("id, slug, content")
  if (error != null) {
    console.error(error.message)
    return
  }
  if (data == null) {
    console.error("New templates returned null instead of empty array?")
    return
  }
  useTemplateStore.setState(() => ({
    lastUpdate: transactionStart,
    slugToTemplate: new Map(data.map(({ slug, content }) => [slug, content])),
  }))
  console.log("...updated templates")
}
