import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { InputFormField } from "@/components/ui/input/input-form-field";
import { TextareaFormField } from "@/components/ui/textarea/textarea-form-field";
import { Database, Tables } from "@/lib/database.types";
import WelcomeToNauvooPageantry from "@/src/components/WelcomeToNauvooPageantry";
import { Svgs } from "@/src/components/svgs";
import PageLayout from "@/src/layouts/PageLayout";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { ChevronLeft, Loader2, Plus, Search, XCircle } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import useFileUpload from "react-use-file-upload";
import { toast } from "sonner";
import { z } from "zod";

const memorabiliaFormSchema = z.object({
  title: z.string(),
  notes: z.string(),
  order: z.number(),
  image_path: z.string().optional().nullable(),
  prices: z.array(
    z.object({
      preId: z.string().optional(),
      size: z.string(),
      price: z.number().optional(),
    })
  ),
});

const UpdateOrderMemorabiliaPage = () => {
  const supabase = useSupabaseClient<Database>();

  const item_id = useParams().item_id;

  const { files, handleDragDropEvent, setFiles, clearAllFiles } =
    useFileUpload();

  const inputFileUploadRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState("");

  const memorabiliaForm = useForm<z.infer<typeof memorabiliaFormSchema>>({
    resolver: zodResolver(memorabiliaFormSchema),
    defaultValues: {
      title: "",
      notes: "",
      order: 0,
      image_path: "",
      prices: [],
    },
  });

  const { fields, remove, insert, update } = useFieldArray({
    control: memorabiliaForm.control,
    name: "prices",
  });

  const file = files?.[0];

  useEffect(() => {
    setFileUrl(file ? URL.createObjectURL(file) : "");
  }, [file]);

  useEffect(() => {
    const fetchItem = async () => {
      if (!item_id) return;

      const { data, error } = await supabase
        .from("souvenir")
        .select("*")
        .eq("id", item_id)
        .maybeSingle();

      if (error) return toast.error(error.message);

      if (data?.image_path) {
        const { data: souvenirImage } = await supabase.storage
          .from("souvenir_photo")
          .createSignedUrl(data?.image_path!, 1000);

        setFileUrl(souvenirImage?.signedUrl!);
      }

      if (data) {
        memorabiliaForm.setValue("title", data.name);
        memorabiliaForm.setValue("notes", data.notes);
        memorabiliaForm.setValue("order", data.order);
        memorabiliaForm.setValue("image_path", data.image_path);

        const { data: sizes, error: sizesError } = await supabase
          .from("souvenir_size")
          .select("*")
          .eq("souvenir_id", item_id);

        if (sizesError) return toast.error(sizesError.message);

        memorabiliaForm.setValue("prices", []);

        sizes?.forEach(({ size, price, id }) => {
          insert(fields.length, { size, price: price!, preId: id });
        });
      }
    };

    fetchItem().finally(() => setIsLoading(false));

    return () => {
      URL.revokeObjectURL(fileUrl);
      memorabiliaForm.setValue("prices", []);
    };
  }, [item_id]);

  const handleUpdateMemorabiliaForm = async (
    values: z.infer<typeof memorabiliaFormSchema>
  ) => {
    let filePath = values.image_path;

    if (files?.[0]) {
      const file = files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = `${values.title}.${fileExt}`;
      filePath = `souvenirs/${fileName}`;

      const { data, error: uploadError } = await supabase.storage
        .from("souvenir_photo")
        .upload(filePath, file, {
          cacheControl: "3600",
          upsert: false,
        });

      if (uploadError) return toast.error(uploadError.message);

      filePath = data?.path!;
    }

    const { error: souvenirError } = await supabase
      .from("souvenir")
      .update({
        name: values.title,
        notes: values.notes,
        order: values.order,
        image_path: filePath,
      })
      .eq("id", item_id!)
      .select("id")
      .maybeSingle();

    if (souvenirError) return toast.error(souvenirError.message);

    let updateSouvenirSizes: Tables<"souvenir_size">[] = [];

    let insertSouvenirSizes: Omit<Tables<"souvenir_size">, "id">[] = [];

    values.prices.forEach(({ size, price, preId }) => {
      if (preId) {
        updateSouvenirSizes.push({
          id: preId!,
          size,
          price: price!,
          souvenir_id: item_id!,
        });
      } else {
        insertSouvenirSizes.push({
          size,
          price: price!,
          souvenir_id: item_id!,
        });
      }
    });

    if (updateSouvenirSizes.length) {
      const { data, error } = await supabase
        .from("souvenir_size")
        .upsert(updateSouvenirSizes, {
          onConflict: "id",
        })
        .eq("souvenir_id", item_id!)
        .select();

      if (error) return toast.error(error.message);

      data?.forEach(({ size, price }) =>
        update(
          values.prices.findIndex((p) => p.size === size),
          { size, price: price! }
        )
      );
    }

    if (insertSouvenirSizes.length) {
      const { data, error } = await supabase
        .from("souvenir_size")
        .insert(insertSouvenirSizes)
        .select();

      if (error) return toast.error(error.message);

      data?.forEach(({ size, price }) =>
        update(
          values.prices.findIndex((p) => p.size === size),
          { size, price: price! }
        )
      );
    }

    clearAllFiles();
    toast.success("Souvenir updated successfully");
  };

  async function handleClearAllFiles() {
    setFileUrl("");
    clearAllFiles();

    if (!memorabiliaForm.getValues("image_path")) return;

    Promise.all([
      supabase.storage
        .from("souvenir_photo")
        .remove([memorabiliaForm.getValues("image_path")!]),
      supabase.from("souvenir").update({ image_path: null }).eq("id", item_id!),
    ]);
  }

  if (isLoading)
    return (
      <PageLayout className="grid place-content-center">
        <Loader2 className="animate-spin w-10 h-10" />
      </PageLayout>
    );

  return (
    <PageLayout className="p-4 space-y-4 container">
      <WelcomeToNauvooPageantry />

      <Button variant="white" className="w-fit" asChild>
        <Link to="/manage/memorabilia" className="flex items-center gap-1">
          <ChevronLeft className="w-5 h-5" />
          Back
        </Link>
      </Button>

      <Form {...memorabiliaForm}>
        <form
          className="space-y-4"
          onSubmit={memorabiliaForm.handleSubmit(handleUpdateMemorabiliaForm)}
        >
          <div className="w-full bg-primary-100 border rounded-sm p-6 space-y-4">
            <InputFormField
              label="Title"
              name="title"
              description="Please share the name of the product."
              className="bg-white border-gray-300"
              placeholder="Type the title of the product here."
              required
            />

            <TextareaFormField
              label="Notes"
              name="notes"
              className="bg-white border-gray-300"
              placeholder="Type your notes here."
              required
            />

            <InputFormField
              label="Order"
              name="order"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (isNaN(value)) return;
                memorabiliaForm.setValue("order", value);
              }}
              type="number"
              className="bg-white border-gray-300"
              placeholder="Type your order here."
              required
            />

            {fileUrl ? (
              <div className="p-8 mx-auto w-full h-full border border-dashed border-gray-300 bg-white rounded-md grid place-content-center place-items-center gap-4">
                <img
                  className="max-w-lg"
                  src={fileUrl}
                  alt="Family Photography"
                />

                <Button variant="destructive" onClick={handleClearAllFiles}>
                  <XCircle />
                  Clear
                </Button>
              </div>
            ) : (
              <div
                onDragEnter={(e) => handleDragDropEvent(e as any)}
                onDragOver={(e) => handleDragDropEvent(e as any)}
                onDrop={(e) => {
                  handleDragDropEvent(e as any);
                  setFiles(e as any);
                }}
                onClick={() => inputFileUploadRef?.current?.click()}
                className="p-8 mx-auto w-full h-full border border-dashed border-gray-300 bg-white rounded-md grid place-content-center place-items-center cursor-pointer"
              >
                <Svgs.cloud_upload className="w-10 h-10" />

                <h6 className="mt-4 font-inter not-italic">
                  <strong>Click to upload</strong> or drag and drop
                </h6>

                <strong className="text-sm text-gray-500">
                  Max. File Size: 30MB
                </strong>

                <Button className="mt-4" type="button">
                  <Search className="w-5 h-5" />
                  Browse File
                </Button>

                <input
                  ref={inputFileUploadRef}
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => {
                    setFiles(e as any);
                  }}
                />
              </div>
            )}

            <div className="flex flex-col gap-2">
              <p className="font-semibold">Size & Price</p>

              <div className="flex flex-col gap-2">
                {fields.map((field, i) => (
                  <div key={field.id} className="flex items-center">
                    <InputFormField
                      containerClassName="w-40"
                      className="h-10 rounded-r-none bg-gray-100 border-gray-300 placeholder:text-gray-400"
                      name={`prices.${i}.size`}
                      placeholder="Type Size"
                      required
                    />

                    {/* <span className="bg-white h-10 flex-1 px-2 border-y border-r border-gray-300 grid place-content-center text-center">
                      {"$"}
                    </span> */}

                    <InputFormField
                      className="h-10 border-x-0 rounded-l-none rounded-r-none bg-white border-gray-300"
                      name={`prices.${i}.price`}
                      type="number"
                      value={field.price?.toFixed(2)}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);

                        memorabiliaForm.setValue(
                          `prices.${i}.price`,
                          isNaN(value) ? 0 : value
                        );
                      }}
                      placeholder="Type your price range. $0.00"
                      required
                    />

                    <Button
                      className="h-auto rounded-l-none shadow-none"
                      onClick={async () => {
                        remove(i);

                        Promise.all([
                          supabase
                            .from("souvenir_size")
                            .delete()
                            .eq("id", field.preId!)
                            .eq("souvenir_id", item_id!),
                        ]);
                      }}
                      variant="default"
                      type="button"
                    >
                      <XCircle />
                    </Button>
                  </div>
                ))}

                <button
                  type="button"
                  className="flex items-center gap-1 text-secondary-500 font-medium"
                  onClick={() =>
                    insert(fields.length, { size: "", price: undefined })
                  }
                >
                  <Plus className="w-5 h-5" /> Add more
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <Button disabled={memorabiliaForm.formState.isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </Form>
    </PageLayout>
  );
};

export default UpdateOrderMemorabiliaPage;
