import { Button } from "@/components/ui/button"
import { Database } from "@/lib/database.types"
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { format } from "date-fns"
import { ArrowLeft, Save, XCircle } from "lucide-react"
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import Template from "@/src/components/Template.tsx"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle"
import { ActionContainer } from "./Constants"

const AvailabilityPage = () => {
  useDocumentTitle(["Availability Preferences"])
  const navigate = useNavigate()
  const supabase = useSupabaseClient<Database>()
  const fromApplication = useFromApplication()
  const urlApplicationId = useParams().application_id

  const [applicationId, setApplicationId] = useState<string | null>(null)

  const [previouslySelectedAvailability, setPreviouslySelectedAvailability] = useState<string[]>([])

  const [selectedAvailability, setSelectedAvailability] = useState<string[]>([])

  const [availability, setAvailability] = useState<
    {
      id: string
      description: string | null
      arrival: Date
      departure: Date
      days: number
      group_type: Database["public"]["Tables"]["group_cast"]["Row"]["group_type"]
    }[]
  >([])

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const fetchPageantYear = useCallback(async () => {
    const pageantYearResult = await supabase
      .from("pageant_year")
      .select("first_performance_on")
      .eq("year", new Date().getFullYear() + 1)

    if (pageantYearResult.error) {
      console.error("error loading pageant_year", pageantYearResult.error)
      return
    }

    return pageantYearResult.data[0].first_performance_on
  }, [supabase])

  const fetchGroupCast = useCallback(
    async (start_date: string) => {
      const groupCastResult = await supabase
        .from("group_cast")
        .select("*")
        .neq("group_type", "CORE_CAST")
        .neq("group_type", "DIRECTING_STAFF")
        .neq("group_type", "VOLUNTEER")

      if (groupCastResult.error) {
        console.error("error loading group_cast list", groupCastResult.error)
        return
      }

      setAvailability(
        groupCastResult.data.map(({ id, description, arrival_day_offset, departure_day_offset, group_type }) => {
          const arrival = new Date(start_date)
          arrival.setDate(arrival.getDate() + arrival_day_offset + 1)

          const departure = new Date(start_date)
          departure.setDate(departure.getDate() + departure_day_offset + 1)

          return {
            id,
            description,
            arrival,
            departure,
            days: departure_day_offset - arrival_day_offset + 1,
            group_type,
          }
        }),
      )
    },
    [supabase],
  )

  const fetchUserData = useCallback(async () => {
    const { data, error } = await fromApplication.select("id, application_availability (group_cast_id)").single()

    if (error) {
      console.error(error)
      return
    }
    setApplicationId(data.id)
    setSelectedAvailability(data.application_availability.map((item) => item.group_cast_id))
    setPreviouslySelectedAvailability(data.application_availability.map((item) => item.group_cast_id))
  }, [fromApplication])

  useEffect(() => {
    const startFetching = async () => {
      const start_date = await fetchPageantYear()
      if (start_date) {
        await fetchGroupCast(start_date)
      }
    }
    fetchUserData()
    startFetching()
  }, [fetchUserData, fetchGroupCast, fetchPageantYear, supabase])

  const handleSaveAvailability = useCallback(async () => {
    if (!applicationId) return toast.error("Application ID is missing")
    const applicationAvailability = selectedAvailability?.map((group_cast_id, index) => ({
      application_id: applicationId,
      preference_order: previouslySelectedAvailability.length + index + 1,
      group_cast_id,
    }))

    await supabase.from("application_availability").delete().eq("application_id", applicationId)

    const { error } = await supabase
      .from("application_availability")
      .upsert(applicationAvailability)
      .eq("application_id", applicationId)

    if (error) return toast.error(error.message)

    toast.success("Availability preferences saved successfully")
  }, [applicationId, previouslySelectedAvailability, selectedAvailability, supabase])

  const isAnyItemSelected = selectedAvailability.length > 0

  const isFamilyCastSelected = useMemo(
    () => availability.find((item) => item.id === selectedAvailability[0])?.group_type === "FAMILY_CAST",
    [availability, selectedAvailability],
  )

  function SortableItem({ id = "", index }: { id: string; index: number }) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id })

    const selected = availability.find((item) => item.id === id)

    if (!selected) return null

    const style: CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? "20" : "auto",
    }

    return (
      <div
        ref={setNodeRef}
        style={style}
        className="w-full flex items-center gap-2 bg-green-600 text-white hover:bg-green-600/90 p-3"
      >
        <div {...attributes} {...listeners} className="cursor-grab active:cursor-grabbing">
          <p>
            {`#${index + 1}: `} {selected.description} ({format(new Date(selected.arrival), "LLLL d")} -{" "}
            {format(new Date(selected.departure), "LLLL d")}) {selected.days} days
          </p>
        </div>

        <XCircle
          className="w-7 h-7 ml-2 z-40 cursor-pointer"
          onClick={async () => {
            if (selectedAvailability.includes(id.toString())) {
              setSelectedAvailability((pre) => pre.filter((item) => item !== id.toString()))

              await supabase.from("application_availability").delete().eq("group_cast_id", id.toString())

              return
            }

            setSelectedAvailability((pre) => [...new Set([...pre, id.toString()])])
          }}
        />
      </div>
    )
  }

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (active.id !== over?.id) {
      const selectedAvailabilityCopy = [...selectedAvailability]
      const oldIndex = selectedAvailabilityCopy.indexOf(active.id as string)
      const newIndex = selectedAvailabilityCopy.indexOf(over?.id as string)

      const reorderedItems = arrayMove(selectedAvailabilityCopy, oldIndex, newIndex)

      setSelectedAvailability(reorderedItems)
    }
  }

  const availabilityMap = new Map<string, typeof availability>()

  availability.forEach((item) => {
    const previousValue = availabilityMap.get(item.group_type)
    availabilityMap.set(item.group_type, [...(previousValue || []), item])
  })

  const availabilityObject = Object.fromEntries(availabilityMap)

  return (
    <>
      <Template slug="apply/_/availability#1" />

      <div className="flex flex-col space-y-4">
        <div className="w-full flex justify-end">
          <Button variant="white" onClick={handleSaveAvailability}>
            <Save />
            Save
          </Button>
        </div>

        {selectedAvailability && selectedAvailability.length > 0 && (
          <div className="p-4 grid gap-4 w-full bg-primary-100 border rounded-sm">
            <div>
              <Template slug="apply/_/availability#3" />
            </div>

            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={selectedAvailability}>
                <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 gap-4 ">
                  {selectedAvailability.map((id, index) => (
                    <SortableItem key={id} id={id} index={index} />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          </div>
        )}

        <div className="grid w-full bg-primary-100 border rounded-sm">
          <div className="p-4">
            <Template slug="apply/_/availability#2" />
          </div>

          {Object.values(availabilityObject)?.map((values, i) => (
            <div
              key={i}
              className="p-4 grid sm:grid-cols-2 md:grid-cols-3 gap-4 border-b last-of-type:border-b-0 first-of-type:border-t"
            >
              {values.map(({ id, description, arrival, departure, days, group_type }) => (
                <Button
                  variant={selectedAvailability.includes(id.toString()) ? "default" : "white"}
                  key={id}
                  className="h-auto p-3"
                  onClick={async () => {
                    if (selectedAvailability.includes(id.toString())) {
                      setSelectedAvailability((pre) => pre.filter((item) => item !== id.toString()))

                      await supabase.from("application_availability").delete().eq("group_cast_id", id.toString())
                      return
                    }

                    setSelectedAvailability((pre) => [...new Set([...pre, id.toString()])])
                  }}
                  // if family cast is selected, disable other casts and vice versa
                  disabled={
                    isAnyItemSelected &&
                    (isFamilyCastSelected ? group_type !== "FAMILY_CAST" : group_type === "FAMILY_CAST")
                  }
                >
                  <p className="text-center">
                    {description} ({format(new Date(arrival), "LLLL d")} - {format(new Date(departure), "LLLL d")}){" "}
                    {days} days
                  </p>
                </Button>
              ))}
            </div>
          ))}
        </div>
        <ActionContainer>
          <Button
            onClick={() => {
              navigate(`/apply/${urlApplicationId}/contact`)
            }}
          >
            <ArrowLeft className="w-5 h-5" />
            Previous Page
          </Button>
          <Button
            onClick={() =>
              handleSaveAvailability().then(() => {
                navigate(`/apply/${urlApplicationId}/details`)
              })
            }
          >
            <Save />
            Save & Continue
          </Button>
        </ActionContainer>
      </div>
    </>
  )
}

export default AvailabilityPage
