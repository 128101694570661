import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App.tsx"
import "./index.css"
import * as Sentry from "@sentry/react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: "https://11ee9a6e197fe69d0e0b5f1867b983b5@o4507766186049536.ingest.us.sentry.io/4507766187819008",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost:12345", "127.0.0.1:12345"],
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>an error has occurred</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
