import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils"
import { ChevronRight } from "lucide-react"
import { useMemo } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import WelcomeToNauvooPageantry from "../components/WelcomeToNauvooPageantry"
import PageLayout from "./PageLayout"

type Props = {
  className?: string
  showFullLogo?: boolean
  showTabs?: boolean
}

const getTabs = (application_id = "") => [
  {
    key: "applicants",
    href: `/apply/${application_id}/applicants`,
    label: "List of Applicants",
  },
  {
    key: "participation",
    href: `/apply/${application_id}/participation`,
    label: "History of Pageant Participation",
  },
  {
    key: "contact",
    href: `/apply/${application_id}/contact`,
    label: "Contact Information",
  },
  {
    key: "photo",
    href: `/apply/${application_id}/photo`,
    label: "Family Photo",
  },
  {
    key: "availability",
    href: `/apply/${application_id}/availability`,
    label: "Availability",
  },
  {
    key: "details",
    href: `/apply/${application_id}/details`,
    label: "Additional Details",
  },
  {
    key: "instruments",
    href: `/apply/${application_id}/instruments`,
    label: "Instruments",
  },
  {
    key: "agreements",
    href: `/apply/${application_id}/agreements`,
    label: "Agreements",
  },
  {
    key: "summary",
    href: `/apply/${application_id}/summary`,
    label: "Summary",
  },
]

const ApplyFormPageLayout = ({ className, showFullLogo = true, showTabs = true }: Props) => {
  const params = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const tabs = useMemo(() => getTabs(params.application_id ?? "_"), [params])

  return (
    <PageLayout className={cn("p-4 container gap-8", className)} {...{ showFullLogo }}>
      {!showTabs && <WelcomeToNauvooPageantry />}

      {showTabs && (
        <>
          <Select
            defaultValue={pathname}
            onValueChange={(value) => {
              navigate(value)
            }}
          >
            <SelectTrigger className="select-trigger-shadcn sm:hidden">
              <SelectValue placeholder="Hello" />
            </SelectTrigger>

            <SelectContent>
              {tabs.map(({ href, label, key }, i) => {
                return (
                  label && (
                    <SelectItem
                      key={key}
                      value={href}
                      className="select-shadcn w-full flex items-center gap-2 flex-shrink-0"
                    >
                      <span
                        className={cn(
                          "!w-5 !h-5 p-1.5 flex-shrink-0 rounded-full grid place-content-center",
                          pathname.includes(href)
                            ? "bg-secondary-500 text-white"
                            : "border border-[#E2E4E9] text-[#85807C]",
                        )}
                      >
                        {i + 1}
                      </span>

                      <span className={cn(pathname.includes(href) ? "font-bold underline" : "text-[#85807C]")}>
                        {label}
                      </span>
                    </SelectItem>
                  )
                )
              })}
            </SelectContent>
          </Select>

          <Tabs
            defaultValue={pathname}
            onValueChange={(value) => {
              navigate(value)
            }}
          >
            <TabsList className="w-full h-auto hidden sm:flex flex-wrap items-center justify-start gap-y-2 p-2 border border-[#B0ADAB] rounded-sm bg-white">
              {tabs.map(
                ({ href, label, key }, i) =>
                  label && (
                    <TabsTrigger
                      key={key}
                      value={href}
                      className="w-fit text-sm flex items-center justify-center space-x-2 !shadow-none data-[state=active]:bg-transparent"
                    >
                      <span
                        className={cn(
                          "w-5 h-5  p-1.5 rounded-full grid place-content-center",
                          pathname.includes(href)
                            ? "bg-secondary-500 text-white"
                            : "border border-[#E2E4E9] text-[#85807C]",
                        )}
                      >
                        {i + 1}
                      </span>

                      <span className={cn(pathname.includes(href) ? "font-bold underline" : "text-[#85807C]")}>
                        {label}
                      </span>

                      {i !== tabs.length - 1 && <ChevronRight className="flex-shrink-0 w-5 h-5 text-[#85807C]" />}
                    </TabsTrigger>
                  ),
              )}
            </TabsList>
          </Tabs>
        </>
      )}
      <Outlet />
    </PageLayout>
  )
}

export default ApplyFormPageLayout
