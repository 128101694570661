import * as React from "react";

import { LucideIcon } from "lucide-react";
import { Control, FieldValues } from "react-hook-form";
import { Textarea } from ".";
import { cn } from "../../../lib/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../form";

export interface InputProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  containerClassName?: string;
  description?: string;
  Icon?: LucideIcon;
  control?: Control<FieldValues>;
}

const TextareaFormField = React.forwardRef<HTMLTextAreaElement, InputProps>(
  (
    {
      className,
      containerClassName,
      name,
      label,
      control,
      description,
      Icon,
      ...props
    },
    ref
  ) => (
    <FormField
      control={control}
      name={name!}
      render={({ field, formState }) => (
        <FormItem className={cn("w-full space-y-1", containerClassName)}>
          {label && (
            <FormLabel htmlFor={name} className="text-primary-500">
              {label}{" "}
              {props?.required && <span className="text-red-500">*</span>}
            </FormLabel>
          )}

          <FormControl>
            <div className="relative">
              {Icon && <Icon className="absolute w-5 h-5 m-2" />}

              <Textarea
                {...field}
                ref={ref}
                {...props}
                name={name}
                id={name}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                disabled={formState.isSubmitting}
                className={cn(className, Icon && "pl-10")}
              />
            </div>
          </FormControl>

          {description && <FormDescription>{description}</FormDescription>}

          <FormMessage />
        </FormItem>
      )}
    />
  )
);

TextareaFormField.displayName = "TextareaFormField";

export { TextareaFormField };
