import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Database, Tables } from "@/lib/database.types";
import { DataTable } from "@/src/components/DataTable";
import { useDialogHandler } from "@/src/hooks/use-dialog-handler";
import PageLayout from "@/src/layouts/PageLayout";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { ColumnDef } from "@tanstack/react-table";
import { Loader2, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ManageOrderMemorabiliaPage = () => {
  const supabase = useSupabaseClient<Database>();

  const { isDialogOpen, onOpen, onOpenChange } = useDialogHandler();

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSouvenir, setSelectedSouvenir] =
    useState<Tables<"souvenir">>();
  const [selectedSouvenirSizes, setSelectedSouvenirSizes] = useState<
    Tables<"souvenir_size">[]
  >([]);
  const [souvenirs, setSouvenirs] = useState<Tables<"souvenir">[]>([]);

  useEffect(() => {
    const fetchItems = async () => {
      const { data } = await supabase
        .from("souvenir")
        .select("*, souvenir_size!inner(souvenir_id, *)");

      if (!data) return;

      setSouvenirs(data!);
    };

    fetchItems().finally(() => setIsLoading(false));
  }, []);

  const columns: ColumnDef<
    Tables<"souvenir"> & {
      souvenir_size: Tables<"souvenir_size">[];
    }
  >[] = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Notes",
      accessorKey: "notes",
    },
    {
      header: "Order",
      accessorKey: "order",
    },
    {
      header: "",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="flex justify-end gap-2">
          <Button
            variant="outline"
            onClick={() => {
              setSelectedSouvenirSizes(row.original.souvenir_size);
              setSelectedSouvenir(row.original);
              onOpen();
            }}
          >
            View Sizes {`(${row.original.souvenir_size.length})`}
          </Button>

          <Button asChild>
            <Link to={`/manage/memorabilia/${row.original.id}`}>Edit</Link>
          </Button>
        </div>
      ),
    },
  ];

  if (isLoading)
    return (
      <PageLayout className="grid place-content-center">
        <Loader2 className="animate-spin w-10 h-10" />
      </PageLayout>
    );

  const filteredSouvenirs = souvenirs.filter(
    (souvenir) =>
      souvenir.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      souvenir.notes.toLowerCase().includes(searchQuery.toLowerCase()) ||
      souvenir.order.toString().includes(searchQuery)
  );

  return (
    <PageLayout className="p-4 space-y-4">
      <Button className="w-fit ml-auto" asChild>
        <Link to="/manage/memorabilia/new">Create New Item</Link>
      </Button>

      <DataTable
        TopActionButtons={() => (
          <div>
            <Input
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        )}
        columns={columns}
        data={filteredSouvenirs}
        hidePagination
      />

      <Sheet
        open={isDialogOpen}
        onOpenChange={(value) => {
          if (!value) {
            setSelectedSouvenir(undefined);
            setSelectedSouvenirSizes([]);
          }
          onOpenChange(value);
        }}
      >
        <SheetContent>
          <SheetHeader>
            <SheetTitle className="text-2xl">
              Souvenir Sizes - {selectedSouvenir?.name}
            </SheetTitle>

            <Button asChild>
              <Link
                to={`/manage/memorabilia/${selectedSouvenir?.id}`}
                className="flex items-center gap-1"
              >
                <Pencil className="w-4 h-4" />
                Edit
              </Link>
            </Button>
          </SheetHeader>

          <div className="mt-4 space-y-2">
            {selectedSouvenirSizes.map((size, i) => (
              <div key={size.id} className="flex justify-between">
                <span>
                  {i + 1}. {size.size}
                </span>
                <span>${size.price?.toFixed(2)}</span>
              </div>
            ))}
          </div>
        </SheetContent>
      </Sheet>
    </PageLayout>
  );
};

export default ManageOrderMemorabiliaPage;
