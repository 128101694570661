import { Button } from "@/components/ui/button"
import { Select } from "@/components/ui/select/index"
import { ArrowLeft, Loader2, Save, Star } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { ActionContainer } from "./Constants"
import { range } from "@/lib/utils.ts"
import Template from "@/src/components/Template.tsx"

const years = range(2005, 2025).map((i) => ({
  value: i,
  label: `Year - ${i}`,
}))

const HistoryOfPageantParticipationPage = () => {
  useDocumentTitle(["History of Participation"])
  const navigate = useNavigate()
  const fromApplication = useFromApplication()
  const urlApplicationId = useParams().application_id

  const [isLoading, setIsLoading] = useState(true)

  const [previousParticipation, setPreviousParticipation] = useState<number[]>([])
  const [previousApplication, setPreviousApplication] = useState<number[]>([])

  useEffect(() => {
    async function fetchApplication() {
      const { data, error } = await fromApplication.select("*").single()

      if (error) {
        console.error("Error fetching Application", error)
      }

      if (data) {
        console.log({ data })
        setPreviousApplication(data.previous_application || [])
        setPreviousParticipation(data.previous_participation || [])
      }

      setIsLoading(false)
    }

    fetchApplication()
  }, [fromApplication])

  const handleAddHistoryOfPageantParticipation = useCallback(async () => {
    const { error } = await fromApplication.update({
      previous_application: previousApplication,
      previous_participation: previousParticipation,
    })

    if (error) console.error(error)
  }, [fromApplication, previousApplication, previousParticipation])

  return (
    <>
      <div className="space-y-4">
        <Template slug="apply/_/participation#1" />
      </div>

      <div className="flex flex-col items-end space-y-2">
        <Button variant="white" onClick={handleAddHistoryOfPageantParticipation}>
          <Save />
          Save
        </Button>

        <div className="w-full bg-primary-100 border rounded-sm p-8 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {isLoading ? (
            <Loader2 className="col-span-full w-6 h-6 animate-spin mx-auto" />
          ) : (
            years.map(({ label, value }) => (
              <div
                key={value}
                className="bg-white border p-2 flex flex-col items-center justify-center gap-4 rounded-sm"
              >
                <div className="flex items-center gap-2">
                  <div className="w-fit p-2 rounded-full border bg-[#FCFBF0]">
                    <Star className="w-5 h-5" />
                  </div>

                  <p className="font-bold text-black">{label}</p>
                </div>

                <Select
                  placeholder="Select..."
                  defaultValue={
                    previousApplication.includes(value)
                      ? "Applied but not selected"
                      : previousParticipation.includes(value)
                        ? "Applied and participated"
                        : "Not applied"
                  }
                  onValueChange={(_value) => {
                    if (_value === "Not applied") {
                      setPreviousApplication((prev) => prev.filter((year) => year !== value))
                      setPreviousParticipation((prev) => prev.filter((year) => year !== value))
                    } else if (_value === "Applied but not selected") {
                      setPreviousApplication((prev) => [...new Set([...prev, value])])

                      setPreviousParticipation((prev) => prev.filter((year) => year !== value))
                    } else {
                      setPreviousParticipation((prev) => [...new Set([...prev, value])])
                      setPreviousApplication((prev) => prev.filter((year) => year !== value))
                    }
                  }}
                  options={[
                    {
                      label: "Applied but not selected",
                      value: "Applied but not selected",
                    },
                    {
                      label: "Applied and participated",
                      value: "Applied and participated",
                    },
                    { label: "", value: "", isSeparator: true },
                    { label: "Not applied", value: "Not applied" },
                  ]}
                  required
                />
              </div>
            ))
          )}
        </div>

        <div></div>
        <ActionContainer>
          <Button
            onClick={() => {
              navigate(`/apply/${urlApplicationId}/applicants`)
            }}
          >
            <ArrowLeft className="w-5 h-5" />
            Previous Page
          </Button>
          <Button
            onClick={() => {
              handleAddHistoryOfPageantParticipation().then(() => {
                navigate(`/apply/${urlApplicationId}/contact`)
              })
            }}
          >
            <Save />
            Save & Continue
          </Button>
        </ActionContainer>
      </div>
    </>
  )
}

export default HistoryOfPageantParticipationPage
