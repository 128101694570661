import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react"
import { useParams } from "react-router-dom"
import { Database } from "@/lib/database.types.ts"
import { useMemo } from "react"

const NotEvaluatedApplicationSelect = () => useSupabaseClient<Database>().from("application").select
const NotEvaluatedApplicationUpdate = () => useSupabaseClient<Database>().from("application").update

type FromApplication = {
  select: ReturnType<typeof NotEvaluatedApplicationSelect>
  update: ReturnType<typeof NotEvaluatedApplicationUpdate>
}

export function useFromApplication() {
  /**
   * selects the "current" application: The application associated with the application_id in the url parameter,
   * or the user's application id if this url parameter is "_"
   *
   * Usage:
   * ```
   * const fromApplication = useFromApplication()
   * useEffect(() => {
   *    fromApplication().select("*").then(({data, error})=> {...})
   * })
   * ```
   */
  const user = useUser()
  const supabase = useSupabaseClient<Database>()
  const application_id = useParams().application_id

  return useMemo(() => {
    if (application_id === undefined || application_id === "_") {
      if (user == null) {
        console.warn("fromApplication called with null user and no application_id. Returning empty query constructors")
        return {
          select: (columns?, headCount?) => supabase.from("application").select(columns, headCount).limit(0),
          update: (values, count) => supabase.from("application").update(values, count).limit(0),
        } as FromApplication
      } else {
        return {
          select: (columns?, headCount?) =>
            supabase.from("application").select(columns, headCount).eq("user_id", user.id),
          update: (values, count) => supabase.from("application").update(values, count).eq("user_id", user.id),
        } as FromApplication
      }
    }
    return {
      select: (columns?, headCount?) =>
        supabase.from("application").select(columns, headCount).eq("id", application_id),
      update: (values, count) => supabase.from("application").update(values, count).eq("id", application_id),
    } as FromApplication
  }, [application_id, supabase, user])
}
