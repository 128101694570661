import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { InputFormField } from "@/components/ui/input/input-form-field"
import { zodResolver } from "@hookform/resolvers/zod"
import { ArrowLeft, CheckCircle, Mail } from "lucide-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { z } from "zod"
import EnquireSection from "../components/EnquireSection"
import { Svgs } from "../components/svgs"
import PageLayout from "../layouts/PageLayout"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { toast } from "sonner"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"

const registerFormSchema = z.object({
  email: z.string().email(),
})

const ForgotPassword = () => {
  useDocumentTitle(["Forgot password"])
  const [isEmailSent, setIsEmailSent] = useState(false)
  const supabase = useSupabaseClient()
  const navigate = useNavigate()

  useEffect(() => {
    const cleanup = supabase.auth.onAuthStateChange(async (event) => {
      if (event == "PASSWORD_RECOVERY") {
        navigate("/reset-password")
      }
    })
    return cleanup.data.subscription.unsubscribe
  }, [navigate, supabase.auth])

  const registerForm = useForm<z.infer<typeof registerFormSchema>>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: {
      email: "",
    },
  })

  async function handleForgotPassword(formData: z.infer<typeof registerFormSchema>) {
    const { error } = await supabase.auth.resetPasswordForEmail(formData.email, {
      redirectTo: "http://" + document.location.host + "/reset-password",
    })
    if (error) {
      console.error(error)
      toast.error(error.message)
    } else {
      setIsEmailSent(true)
    }
  }

  return (
    <PageLayout className="p-10 items-center">
      <div className="border p-8 max-w-lg w-full m-auto rounded-sm space-y-4">
        {!isEmailSent && (
          <Link to="/login" className="text-sm text-primary-500 flex items-center gap-1">
            <ArrowLeft className="w-5 h-5" />
            Back
          </Link>
        )}

        <div className="w-full flex items-center gap-8">
          <h3 className="flex-shrink-0">Forgot Password</h3>

          <Svgs.title_leaf className="w-full h-full mt-1" />
        </div>

        {isEmailSent ? (
          <>
            <div className="w-full p-2 flex flex-col gap-2 border bg-primary-100 rounded-sm">
              <div className="w-fit p-2 rounded-full border">
                <CheckCircle />
              </div>

              <p className="text-sm">A password reset link has been sent to your email successfully.</p>
            </div>

            <p className="p-2 bg-secondary-500 rounded-sm text-center text-white">Thank you</p>
          </>
        ) : (
          <Form {...registerForm}>
            <form onSubmit={registerForm.handleSubmit(handleForgotPassword)} className="grid gap-4 ">
              <InputFormField
                Icon={Mail}
                label="Email Address"
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
                description="Your email is case sensitive."
                required
              />

              <Button size="lg">Reset</Button>
            </form>
          </Form>
        )}
      </div>

      <EnquireSection />
    </PageLayout>
  )
}

export default ForgotPassword
