import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input/index"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Database } from "@/lib/database.types"
import { cn } from "@/lib/utils"
import { DataTable } from "@/src/components/DataTable"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { ColumnDef } from "@tanstack/react-table"
import { Loader2, Pencil, SearchIcon } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
// import { useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
// import { useDialogHandler } from "../../hooks/use-dialog-handler"
import PageLayout from "../../layouts/PageLayout"
import { QueryData } from "@supabase/supabase-js"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card.tsx"

// duplicated from Summary.tsx
const QUERY_STRING = `id, notification_emails, state, description, previous_participation, previous_application, notification_cellphones, address1, address2, city, state, zip, country, name, 
  applicant ( id, given_name, family_name, female, birth_date ), application_availability (preference_order, group_cast ( group_color, description, slug ))`
const NOT_EXECUTED = () => useSupabaseClient<Database>().from("application").select(QUERY_STRING).single()
type FullApplication = QueryData<ReturnType<typeof NOT_EXECUTED>>

const FamilyCast = () => {
  const supabase = useSupabaseClient<Database>()

  const navigation = useNavigate()
  const [searchParams] = useSearchParams()

  // const { isDialogOpen, onOpenChange, onOpen } = useDialogHandler()
  //
  // const form = useForm()

  const castingGroup = searchParams.get("casting_group")

  const [copiedEmail, setCopiedEmail] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCastingGroup, setSelectedCastingGroup] = useState(castingGroup || "")
  const [applications, setApplications] = useState<FullApplication[]>([])

  useEffect(() => {
    async function fetchApplicants() {
      const { error, data } = await supabase
        .from("application")
        .select(QUERY_STRING)
        .not("first_submitted_at", "is", null)
      if (error) {
        console.error("Error fetching applications", error)
      }
      if (!data) {
        return
      }

      setApplications([...data])
      setIsLoading(false)
    }

    fetchApplicants()
  }, [supabase])

  const parsedApplications = useMemo(() => {
    return applications.map(
      ({
        id,
        name,
        notification_emails,
        notification_cellphones,
        address1,
        address2,
        city,
        state,
        zip,
        country,
        previous_application,
        previous_participation,
        description,
        application_availability,
        applicant,
      }) => ({
        id,
        description,
        name,
        notification_emails,
        notification_cellphones,
        state,
        address: [address1, address2, `${city}, ${state} ${zip}`, country],
        application_availability: application_availability
          .filter((a) => a.group_cast != null)
          .sort((a, b) => a.preference_order - b.preference_order),
        applicant,
        admissions: `${previous_participation?.length || 0} / ${(previous_application?.length || 0) + (previous_participation?.length || 0)}`,
        icon_url: supabase.storage.from("family_photo").getPublicUrl(`${id}.jpg`, {
          transform: {
            height: 256,
            resize: "contain",
          },
        }).data.publicUrl,
        full_url: supabase.storage.from("family_photo").getPublicUrl(`${id}.jpg`, {
          transform: {
            width: 1920,
            resize: "contain",
          },
        }).data.publicUrl,
      }),
    )
  }, [applications, supabase.storage])

  let filteredApplications = parsedApplications
  if (selectedCastingGroup != "") {
    filteredApplications = parsedApplications.filter(
      (app) => app.application_availability[0].group_cast!.slug == selectedCastingGroup,
    )
  }

  const columns: ColumnDef<(typeof filteredApplications)[0]>[] = [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    // },
    // {
    //   accessorKey: "link",
    //   header: "Link to Application",
    //   cell: ({ row }) => (
    //     <Link className="w-20 center" to={`/apply/${row.original.id ?? "_"}/confirm`}>
    //       <Button variant={"link"}>{`${row.original.id ?? "_"}`}</Button>
    //     </Link>
    //   ),
    // },
    {
      accessorKey: "name",
      header: () => (
        <div className="grid family-cast-table-header">
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>Application Name</span>
        </div>
      ),
      cell: ({ row }) => (
        <Link className="flex flex-row items-stretch h-full" to={`/apply/${row.original.id ?? "_"}/summary`}>
          <Button size="icon" className="w-7 h-7 rounded-[50%] flex-shrink-0">
            <Pencil className="w-4 h-4" />
          </Button>
          <Button variant={"link"}>{row.getValue("name")}</Button>
        </Link>
      ),
    },
    {
      accessorKey: "application_availability",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>Casts Available</span>
        </div>
      ),
      cell: ({ row }) => (
        <ol>
          {row.getValue<FullApplication["application_availability"]>("application_availability").map((a) => (
            <li key={a.group_cast!.description} className="whitespace-nowrap">
              {a.preference_order}. {a.group_cast!.description}
            </li>
          ))}
        </ol>
      ),
    },

    // {
    //   accessorKey: "cast_pref",
    //   header: () => (
    //     <div className="grid family-cast-table-header">
    //       <span className="text-transparent select-none">X</span>
    //       <span>{filteredApplications.length}</span>
    //       <span>Cast Preference</span>

    //       <div className="!p-0 !px-2 flex items-center border-b">
    //         <p className="bg-rose-100 w-8 grid place-content-center py-2 text-sm">R</p>
    //         <p className="bg-blue-100 w-8 grid place-content-center py-2 text-sm">B</p>
    //         <p className="bg-yellow-100 w-8 grid place-content-center py-2 text-sm">Y</p>
    //         <p className="bg-green-100 w-8 grid place-content-center py-2 text-sm">Gr</p>
    //         <p className="bg-orange-100 w-8 grid place-content-center py-2 text-sm">Go</p>
    //       </div>
    //     </div>
    //   ),
    //   cell: () => {
    //     return (
    //       <div className="!p-0 flex items-center">
    //         <p className="bg-rose-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-blue-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-yellow-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-green-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //         <p className="bg-orange-100 w-8 grid place-content-center py-2 text-sm">
    //           <Check className="w-5 h-5" />
    //         </p>
    //       </div>
    //     )
    //   },
    // },
    {
      accessorKey: "photo",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>Photo</span>
        </div>
      ),
      cell: ({ row }) => (
        <HoverCard>
          <HoverCardTrigger>
            <img src={row.original.icon_url} width={256} height={256} />
          </HoverCardTrigger>
          {/*todo: fix hovercard not finding default background color*/}
          <HoverCardContent className="bg-white h-fit w-fit">
            <img className="max-w-[100vw] max-h-[50vh]" src={row.original.full_url} />
          </HoverCardContent>
        </HoverCard>
      ),
    },
    {
      accessorKey: "description",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>&nbsp;</span>
          <span>Bio</span>
        </div>
      ),
      cell: ({ row }) => (
        <div className="w-80">{row.getValue("description")}</div>
        // <HoverCard>
        //   <HoverCardTrigger>
        //     <div className="w-80">
        //       {row.getValue("description")}
        //     </div>
        //   </HoverCardTrigger>
        //   <HoverCardContent className="bg-white w-80">{row.getValue("description")}</HoverCardContent>
        // </HoverCard>
      ),
    },
    {
      accessorKey: "admissions",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>&nbsp;</span>
          <span>Admissions</span>
        </div>
      ),
    },
    {
      accessorKey: "Notes",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>Notes</span>
        </div>
      ),
    },
    {
      accessorKey: "applicant",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>Family</span>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <ol>
            {row.getValue<FullApplication["applicant"]>("applicant").map((a) => (
              <li key={a.id} className="whitespace-nowrap">
                {a.given_name} {a.family_name}{" "}
                {a.birth_date ? new Date("2025-07-08").getFullYear() - new Date(a.birth_date).getFullYear() : ""}
                {a.female ? "F" : "M"}
              </li>
            ))}
          </ol>
        )
      },
    },
    {
      accessorKey: "state",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>State</span>
        </div>
      ),
    },
    {
      accessorKey: "address",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
          <span>Address</span>
        </div>
      ),
      cell: ({ row }) => (
        <ul>
          {row.getValue<string[]>("address").map((item: string, index: number) => (
            <li key={index} className="whitespace-nowrap">
              {item}
            </li>
          ))}
        </ul>
      ),
    },
    {
      accessorKey: "notification_emails",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-sky-200">Totals Family Members Applied:</span>
          <span>Only Those with "Y" in Column B:</span>
          <span className="text-transparent select-none">X</span>
          <span>Emails, Cellphones</span>
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="grid">
            {row.original?.notification_emails?.map((email) => (
              <span
                className={cn(
                  "px-2 py-1 w-fit relative text-blue-600 cursor-pointer underline",
                  copiedEmail === `${row.original}-${email}` && "text-black",
                )}
                onClick={async () => {
                  setCopiedEmail(`${row.original}-${email}`)

                  await navigator.clipboard.writeText(email)
                }}
                key={email}
              >
                {email}

                {copiedEmail === `${row.original}-${email}` && (
                  <span className="top-0 left-0 absolute w-full h-full bg-green-500/90 rounded-md font-semibold text-center grid place-content-center">
                    Copied
                  </span>
                )}
              </span>
            ))}
            {row.original?.notification_cellphones?.map((phoneNumber) => (
              <span key={phoneNumber}>
                {phoneNumber}
                <br />
              </span>
            ))}
          </div>
        )
      },
    },
    {
      accessorKey: "M18-64",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-sky-200">23</span>
          <span className="bg-sky-200">23</span>
          <span className="bg-sky-200">Temple (20)</span>
          <span className="bg-sky-200">M18-64</span>
        </div>
      ),
      cell: ({ row }) => {
        return <span className="bg-sky-200">{row.getValue("M18-64")}</span>
      },
    },
    {
      accessorKey: "F18-64",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-rose-200">28</span>
          <span className="bg-rose-200">28</span>
          <span className="bg-sky-200">Panels(36)</span>
          <span className="bg-rose-200">F18-64</span>
        </div>
      ),
    },
    {
      accessorKey: "M14-17",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-sky-200">14</span>
          <span className="bg-sky-200">14</span>
          <span className="bg-sky-200">Pillars (14)</span>
          <span className="bg-sky-200">M14-17</span>
        </div>
      ),
    },
    {
      accessorKey: "M8-11",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-sky-200">14</span>
          <span className="bg-sky-200">14</span>
          <span className="bg-sky-200">Flag/Stick (10)</span>
          <span className="bg-sky-200">M8-11</span>
        </div>
      ),
    },
    {
      accessorKey: "M4-7",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-sky-200">4</span>
          <span className="bg-sky-200">4</span>
          <span className="bg-sky-200">Stickball (5)</span>
          <span className="bg-sky-200">M4-7</span>
        </div>
      ),
    },
    {
      accessorKey: "F4-11",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-rose-200">9</span>
          <span className="bg-rose-200">9</span>
          <span className="bg-rose-200">Stickball (5)</span>
          <span className="bg-rose-200">F4-11</span>
        </div>
      ),
    },
    {
      accessorKey: "M1-3",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-sky-200">1</span>
          <span className="bg-sky-200">1</span>
          <span className="bg-sky-200">Jamie (3)</span>
          <span className="bg-sky-200">M1-3</span>
        </div>
      ),
    },
    {
      accessorKey: "Other",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-violet-200">28</span>
          <span className="bg-violet-200">28</span>
          <span className="bg-violet-200">All Others</span>
          <span className="bg-violet-200">Other</span>
        </div>
      ),
    },
    {
      accessorKey: "121",
      header: () => (
        <div className="grid family-cast-table-header">
          <span className="bg-green-200">121</span>
          <span className="bg-green-200">121</span>
          <span className="text-transparent select-none">X</span>
          <span className="text-transparent select-none">X</span>
        </div>
      ),
    },
  ]

  const castingGroups = [
    {
      key: "family_red",
      value: "Red Cast",
    },
    {
      key: "family_blue",
      value: "Blue Cast",
    },
    {
      key: "family_yellow",
      value: "Yellow Cast",
    },
    {
      key: "family_green",
      value: "Green Cast",
    },
    {
      key: "family_gold",
      value: "Gold Cast",
    },
  ]

  return (
    <PageLayout className="p-4 gap-8 family-cast-table" showFullLogo>
      {isLoading ? (
        <div className="flex-1 grid place-content-center">
          <Loader2 className="w-6 h-6 animate-spin" />
        </div>
      ) : (
        <DataTable
          isLoading={isLoading}
          TopActionButtons={() => (
            <div className="relative flex items-center">
              <SearchIcon className="ml-2 absolute w-5 h-5 text-[#85807C]" />
              <Input placeholder="Search" className="h-9 bg-white pl-10" />
            </div>
          )}
          EndActionButtons={() => (
            <Select
              value={selectedCastingGroup}
              onValueChange={(e) => {
                setSelectedCastingGroup(e)
                if (e != "") {
                  navigation(`/casting/family?casting_group=${e}`)
                } else {
                  navigation(`/casting/family`)
                }
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value="">None</SelectItem>

                {castingGroups.map(({ key, value }) => (
                  <SelectItem key={key} value={key}>
                    {value}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          columns={columns}
          data={filteredApplications}
          hidePagination
        />
      )}
    </PageLayout>
  )
}

export default FamilyCast
