import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  Button,
  headingsPlugin,
  MDXEditor,
  MDXEditorMethods,
  Select,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor"
import "@mdxeditor/editor/style.css"
import PageLayout from "@/src/layouts/PageLayout.tsx"
import { forceRefreshTemplates, useTemplateStore } from "@/src/components/Template.tsx"
import { ReactElement, useCallback, useMemo, useRef, useState } from "react"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { toast } from "sonner"
import Fuse from "fuse.js"
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"

function contextualize(start: number, end: number, value: string): ReactElement {
  const pre = value.substring(0, start)
  const mid = <mark>{value.substring(start, end)}</mark>
  const post = value.substring(end)
  return (
    <div className="w-full flex flex-nowrap">
      <div className="w-96 max-w-fit overflow-hidden">
        <div style={{ whiteSpaceCollapse: "preserve-spaces" }} className="float-right whitespace-nowrap">
          {pre}
        </div>
      </div>
      <div style={{ whiteSpaceCollapse: "preserve-spaces" }} className="whitespace-nowrap">
        {mid}
      </div>
      <div
        style={{ whiteSpaceCollapse: "preserve-spaces" }}
        className="w-96 max-w-fit whitespace-nowrap overflow-hidden"
      >
        {post}
      </div>
    </div>
  )
}

function SearchDialog({ selectTemplateOnChange }: { selectTemplateOnChange: (value: string) => void }) {
  const templates = useTemplateStore((s) => s.slugToTemplate)
  const fuse = useMemo(() => {
    const fuseObjects = [...templates.entries()].map(([id, content]) => ({ id, content }))
    return new Fuse(fuseObjects, {
      keys: ["name", "content"],
      includeMatches: true,
      includeScore: true,
      minMatchCharLength: 3,
      threshold: 0.3,
      ignoreLocation: true,
    })
  }, [templates])

  const [query, setQuery] = useState("")
  const [open, setOpen] = useState(false)

  const filteredSelectItems = useMemo(() => {
    return fuse
      .search(query)
      .filter(({ matches }) => matches && matches.length > 0)
      .map(({ item: { id }, matches }) => {
        const { value, indices } = matches![0]
        //get longest match first
        const [start, end] = [...indices].sort(([s1, e1], [s2, e2]) => e2 - s2 - (e1 - s1))[0]
        return {
          label: (
            <div className="w-full">
              {id}
              {contextualize(start, end + 1, value!)}
            </div>
          ), // why is end inclusive? why is value nullable?
          id,
        }
      })
  }, [fuse, query])
  return (
    <CommandDialog
      open={open}
      onOpenChange={setOpen}
      shouldFilter={false}
      trigger={<div className={"_toolbarButton_yms4a_237"}>Search for a Template</div>}
    >
      <CommandInput placeholder="Type some text from a template" value={query} onValueChange={setQuery} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {filteredSelectItems.map(({ label, id }) => (
            <CommandItem
              value={id}
              onSelect={() => {
                selectTemplateOnChange(id)
                setOpen(false)
              }}
            >
              {label}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  )
}

const TemplateEditor = () => {
  //sorta constants
  const supabase = useSupabaseClient()
  const mdxEditorRef = useRef<MDXEditorMethods>(null)
  const templates = useTemplateStore((s) => s.slugToTemplate)

  //state
  const [saving, setSaving] = useState<boolean>(false)
  const [currentTemplateSlug, setCurrentTemplateSlug] = useState<string>(templates.keys().next().value)

  useDocumentTitle([currentTemplateSlug, "Template Editor"])

  const selectItems = useMemo(
    () =>
      [...templates.entries()].map(([id]) => ({
        label: `${id}`,
        value: id,
      })),
    [templates],
  )

  //callbacks
  const selectTemplateOnChange = useCallback(
    (value: string) => {
      if (value == "null") {
        return
      }
      setCurrentTemplateSlug(value)
      mdxEditorRef.current?.setMarkdown(templates.get(value)!)
    },
    [templates],
  )

  const deployTemplate = useCallback(async () => {
    try {
      setSaving(true)
      console.log("select", await supabase.from("template").select("*").eq("slug", currentTemplateSlug))
      const markdown = mdxEditorRef.current?.getMarkdown()
      if (!markdown) {
        toast.error("Editor content not found")
      }
      const { error } = await supabase.from("template").update({ content: markdown }).eq("slug", currentTemplateSlug)
      if (error) {
        toast.error(error.message)
        console.error(error)
        return
      }
      toast.success("Template updated successfully!")
    } finally {
      await forceRefreshTemplates(supabase)
      setSaving(false)
    }
  }, [currentTemplateSlug, supabase])

  return (
    <PageLayout className="border p-8 ">
      <h2>Important notes:</h2>
      <ul className="list-disc ml-4 pb-3">
        <li>Headings must be on a separate line</li>
        <li>Deploying a template deploys the template for every user immediately. Be careful!</li>
      </ul>

      <MDXEditor
        ref={mdxEditorRef}
        className="h-full border-2"
        markdown={templates.get(currentTemplateSlug) ?? ""}
        plugins={[
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <UndoRedo />
                <BlockTypeSelect />
                <BoldItalicUnderlineToggles />
                <Select
                  value={currentTemplateSlug}
                  onChange={selectTemplateOnChange}
                  triggerTitle="Select Template"
                  placeholder=""
                  items={selectItems}
                />
                <SearchDialog selectTemplateOnChange={selectTemplateOnChange} />
                <Button className="ml-auto" disabled={saving} onClick={deployTemplate}>
                  Deploy Template
                </Button>
              </>
            ),
          }),
          headingsPlugin(),
        ]}
      />
    </PageLayout>
  )
}

export default TemplateEditor
