import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { useCallback, useEffect, useState } from "react"
import { ArrowLeft, ArrowRight, ChevronsUpDown, Loader2, Save, Trash } from "lucide-react"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx"
import { Button } from "@/components/ui/button.tsx"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { Database } from "@/lib/database.types.ts"
import Template from "@/src/components/Template.tsx"
import { ActionContainer } from "./Constants"
import { useNavigate, useParams } from "react-router-dom"

const QUERY = "id, applicant ( id, family_name, given_name, instrument ( id, description )) "

const DEFAULT_INSTRUMENTS = ["Violin", "Viola", "Guitar", "Drums", "Cello", "Flute"].sort()

const NOT_EXECUTED = () => useFromApplication().select(QUERY).single()

const InstrumentsPage = () => {
  useDocumentTitle(["Instruments"])
  const supabase = useSupabaseClient<Database>()
  const params = useParams()
  const fromApplication = useFromApplication()
  const navigate = useNavigate()
  const [state, setState] = useState<Awaited<ReturnType<typeof NOT_EXECUTED>> | null>(null)

  const updateStateCallback = useCallback(async () => {
    const result = await fromApplication.select(QUERY).single()
    setState(result)
  }, [fromApplication])

  useEffect(() => {
    updateStateCallback()
  }, [fromApplication, updateStateCallback])

  if (state == null) {
    return <Loader2 className="animate-spin w-10 h-10" />
  } else if (state.error) {
    console.error("Error loading instruments", state.error)
    return "Error loading instruments"
  }
  return (
    <>
      <div className="space-y-4">
        <Template slug={"apply/_/instruments#1"} />
        {state.data.applicant.length == 0 ? (
          <div>You must add applicants before you can enter what instruments they play.</div>
        ) : (
          state.data.applicant
            .sort((a, b) => `${a.given_name} ${a.family_name}`.localeCompare(`${b.given_name} ${b.family_name}`))
            .map(({ id: applicantId, family_name, given_name, instrument }) => (
              <div className="space-y-2" key={applicantId}>
                <h2>
                  {given_name} {family_name}
                </h2>
                <ul className="space-y-2">
                  {instrument
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .map(({ id: instrumentId, description }) => (
                      <Button
                        variant={"outline"}
                        className="w-[200px] flex flex-row content-between items-center"
                        key={instrumentId}
                        onClick={async () => {
                          await supabase.from("instrument").delete().eq("id", instrumentId)
                          await updateStateCallback()
                        }}
                      >
                        <span className="mr-auto text-center">{description}</span>
                        <Trash className="ml-auto w-4 h-4" />
                      </Button>
                    ))}
                  <InstrumentPopover applicantId={applicantId} updateStateCallback={updateStateCallback} />
                </ul>
              </div>
            ))
        )}
        <ActionContainer>
          <Button
            onClick={() => {
              navigate(`/apply/${params.application_id}/details`)
            }}
          >
            <ArrowLeft className="w-5 h-5" />
            Previous Page
          </Button>
          <Button
            onClick={() => {
              navigate(`/apply/${params.application_id}/agreements`)
            }}
          >
            Continue
            <ArrowRight className="w-5 h-5" />
          </Button>
        </ActionContainer>
      </div>
    </>
  )
}

function InstrumentPopover({
  applicantId,
  updateStateCallback,
}: {
  applicantId: string
  updateStateCallback: () => Promise<void>
}) {
  const supabase = useSupabaseClient<Database>()

  const [searchText, setSearchText] = useState("")

  const [open, setOpen] = useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-[200px] justify-between">
          {"Add instrument..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command className="bg-background">
          <CommandInput placeholder="Search instruments..." value={searchText} onValueChange={setSearchText} />
          <CommandList>
            <CommandEmpty
              className="py-6 text-center text-sm overflow-x-hidden hover:bg-gray-100/90"
              onClick={async () => {
                await supabase
                  .from("instrument")
                  .upsert(
                    { applicant_id: applicantId, description: searchText },
                    { ignoreDuplicates: true, onConflict: "applicant_id, description" },
                  )
                await updateStateCallback()
                setSearchText("")
                setOpen(false)
              }}
            >
              <div>Add new instrument.</div>
            </CommandEmpty>
            <CommandGroup>
              {DEFAULT_INSTRUMENTS.map((instrument) => (
                <CommandItem
                  key={instrument}
                  value={instrument}
                  onSelect={async () => {
                    await supabase
                      .from("instrument")
                      .upsert(
                        { applicant_id: applicantId, description: instrument },
                        { ignoreDuplicates: true, onConflict: "applicant_id, description" },
                      )

                    await updateStateCallback()
                    setOpen(false)
                  }}
                >
                  {instrument}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default InstrumentsPage
