import { cn } from "@/lib/utils"
import { Svgs } from "./svgs"
import Template from "@/src/components/Template.tsx"

const EnquireSection = ({ isSmallScreen = false }) => {
  return (
    <div
      className={cn(
        "p-4 border grid gap-4 rounded-sm",
        isSmallScreen ? "xl:h-14 md:grid-cols-2 xl:grid-cols-3" : "lg:h-14 md:grid-cols-2 lg:grid-cols-3",
      )}
    >
      <Template
        slug="enquire/#1"
        className={cn("text-sm", isSmallScreen ? "col-span-2 xl:col-span-1" : "col-span-2 lg:col-span-1")}
      />

      <div
        className={cn(
          "w-full p-2 flex flex-col gap-2 italic border bg-primary-100 rounded-sm",
          isSmallScreen ? "xl:-mt-8" : "lg:-mt-8",
        )}
      >
        <Svgs.mail />

        <Template className={cn("font-crimson text-xl", isSmallScreen && "text-lg")} slug="enquire/#email" />
      </div>

      <div
        className={cn(
          "w-full p-2 flex flex-col gap-2 italic border bg-primary-100 rounded-sm",
          isSmallScreen ? "xl:-mt-8" : "lg:-mt-8",
        )}
      >
        <Svgs.phone />

        <Template className={cn("font-crimson text-xl", isSmallScreen && "text-lg")} slug="enquire/#phone" />
      </div>
    </div>
  )
}

export default EnquireSection
