import { cn } from "@/lib/utils";
import React from "react";
import Header from "../components/Header";

type Props = {
  className?: string;
  children: React.ReactNode;
  showFullLogo?: boolean;
};

const PageLayout = ({ className, children, showFullLogo = false }: Props) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header {...{ showFullLogo }} />

      <div className={cn("flex flex-col flex-1 w-full h-full", className)}>
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
