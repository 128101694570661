import { Button } from "@/components/ui/button"
import { Database, Tables } from "@/lib/database.types"
import { DataTable } from "@/src/components/DataTable"
import PageLayout from "@/src/layouts/PageLayout"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { ColumnDef } from "@tanstack/react-table"
import { Loader2 } from "lucide-react"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { toast } from "sonner"
import useCurrentApplicationIdSlow from "@/src/utils/useCurrentApplicationIdSlow.ts"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"

const ArrivePhotosPage = () => {
  useDocumentTitle(["Arrive Photos"])
  const application_id = useCurrentApplicationIdSlow()
  const supabase = useSupabaseClient<Database>()

  const inputFileRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [selectedApplicant, setSelectedApplicant] = useState("")
  const [applicants, setApplicants] = useState<
    (Tables<"applicant"> & {
      image?: string
    })[]
  >([])

  async function fetchApplicants() {
    const { data, error } = await supabase.from("applicant").select("*").eq("application_id", application_id!)

    if (error) {
      console.error("Error fetching applicants", error)
    }

    const { data: images } = await supabase.storage.from("applicant_photo").list(`${application_id}/`)

    if (data)
      setApplicants(
        data.map((applicant) => {
          const imageObject = images?.find((image) => image.name.includes(applicant.id))
          let url = undefined
          if (imageObject) {
            url = supabase.storage.from("applicant_photo").getPublicUrl(`${application_id}/${imageObject.name}`)
              .data.publicUrl
          }
          return {
            ...applicant,
            image: url,
          }
        }),
      )

    setIsLoading(false)
  }

  useEffect(() => {
    if (application_id) fetchApplicants()
  }, [application_id])

  const columns: ColumnDef<
    Tables<"applicant"> & {
      image?: string
    }
  >[] = [
    {
      accessorKey: "given_name",
      header: "Given Name",
      cell: ({ row }) => <span className="font-bold">{row.original.given_name}</span>,
    },
    {
      accessorKey: "photo",
      header: "Photo",
      cell: ({ row }) => {
        return !row.original.image ? (
          <p>{"-"}</p>
        ) : (
          <img src={row.original.image} alt="applicant" className="w-20 h-20 object-cover" />
        )
      },
    },
    {
      accessorKey: "family_name",
      header: "Surname",
      cell: ({ row }) => <span className="font-bold">{row.original.family_name}</span>,
    },
    {
      accessorKey: "birth_date",
      header: "Birth Date",
      cell: ({ row }) => <span className="font-bold">{row.getValue("birth_date")}</span>,
    },
    {
      accessorKey: "female",
      header: "Sex",
      cell: ({ row }) => <span className="font-bold">{row.getValue("female") ? "Female" : "Male"}</span>,
    },
    {
      accessorKey: "actions",
      header: "",
      cell: ({ row }) => (
        <Button
          onClick={() => {
            setSelectedApplicant(row.original.id)
            inputFileRef.current?.click()
          }}
          disabled={isUploadingImage}
          className="flex-shrink-0 w-full"
        >
          {isUploadingImage && selectedApplicant === row.original.id ? (
            <Loader2 className="animate-spin" />
          ) : (
            "Upload Image"
          )}
        </Button>
      ),
      size: 50,
    },
  ]

  async function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    setIsUploadingImage(true)

    try {
      if (!selectedApplicant) {
        toast.error("Please select an applicant first")
        return
      }

      const file = e.target.files?.[0]

      if (!file) {
        toast.error("No file selected")
        return
      }
      const extension = file.name.split(".").slice(-1)[0]

      const { error } = await supabase.storage
        .from("applicant_photo")
        .upload(`${application_id}/${selectedApplicant}.${extension}`, file, {
          contentType: "image/*",
          upsert: true,
        })

      if (inputFileRef.current) inputFileRef.current.value = ""

      if (error) {
        toast.error(error.message)
        console.error(error)
      }

      fetchApplicants()

      toast.success("Image uploaded successfully")
    } finally {
      setIsUploadingImage(false)
    }
  }

  return (
    <PageLayout className="p-4">
      {isLoading ? (
        <Loader2 className="animate-spin w-fit mx-auto" />
      ) : (
        <div className="flex flex-col items-end gap-4">
          <DataTable TopActionButtons={() => <div />} columns={columns} data={applicants} hidePagination />
        </div>
      )}

      <input ref={inputFileRef} onChange={handleFileUpload} type="file" hidden />
    </PageLayout>
  )
}

export default ArrivePhotosPage
