import Template from "@/src/components/Template.tsx"

const WelcomeToNauvooPageantry = () => {
  return (
    <section>
      <div className="w-full relative max-h-52 h-full flex justify-between">
        <div className="hidden md:block -z-10 absolute left-0 top-0 w-full h-full bg-primary-500 md:bg-gradient-to-r from-primary-500 via-primary-500 to-[#4F361B52]" />

        <div className="bg-primary-500 p-4 sm:p-8 space-y-4 flex flex-col items-center justify-center max-w-full md:max-w-3xl">
          <Template
            markdownComponents={{
              h1: (props) => (
                <h1
                  {...props}
                  className="w-full font-crimson text-white leading-none text-2xl sm:text-3xl md:text-4xl lg:text-5xl"
                />
              ),
            }}
            slug={"apply/_/#header"}
            className="text-white text-xs md:text-sm lg:text-base"
          />
        </div>

        <div className="hidden md:block">
          <img
            style={{
              maskImage: "linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0))",
            }}
            className="w-full h-full object-cover"
            src="/register-page.jpeg"
            alt="Nauvoo"
            draggable={false}
          />
        </div>
      </div>
    </section>
  )
}

export default WelcomeToNauvooPageantry
