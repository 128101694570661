import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import Cropper, { Area } from "react-easy-crop"
import { useDropzone } from "react-dropzone"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { Database } from "@/lib/database.types.ts"
import useCurrentApplicationIdSlow from "@/src/utils/useCurrentApplicationIdSlow.ts"
import Template from "@/src/components/Template.tsx"
import { ArrowLeft, File, Save, XCircle } from "lucide-react"
import { Button } from "@/components/ui/button.tsx"
import { cn } from "@/lib/utils.ts"
import { useNavigate } from "react-router-dom"
import { Svgs } from "@/src/components/svgs.tsx"
import getCroppedImg from "@/lib/cropImage.ts"
import { toast } from "sonner"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { ActionContainer } from "./Constants"

const MyImageCropper = ({
  imageUrl,
  setCroppedAreaPixels,
}: {
  imageUrl: string
  setCroppedAreaPixels: Dispatch<SetStateAction<Area | null>>
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }
  return (
    <>
      <div className={cn("w-full bg-primary-100 border rounded-sm p-8 h-[500px]")}>
        <div className="w-full h-full">
          <Cropper
            style={{
              containerStyle: {
                position: "relative",
                width: "100%",
                height: "100%",
              },
            }}
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls">
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(Number(e.target.value))
            }}
            className="zoom-range"
          />
        </div>
      </div>
    </>
  )
}

const MyFileDropper = ({ setFileUrl }: { setFileUrl: Dispatch<SetStateAction<string | null>> }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((acceptedFile) => {
        const reader = new FileReader()
        reader.onabort = () => console.error("file reading was aborted")
        reader.onerror = () => console.error("file reading has failed")
        reader.onload = () => {
          if (reader.error) {
            console.error(reader.error)
            return
          }
          const binaryStr = reader.result
          setFileUrl(binaryStr as string | null)
        }
        reader.readAsDataURL(acceptedFile)
      })
    },
    [setFileUrl],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [".bmp", ".gif", ".jpeg", ".jpeg", ".png", ".webp"] },
    maxFiles: 1,
  })
  return (
    <div className="w-full bg-primary-100 border rounded-sm p-8 h-[300px] ">
      <div
        {...getRootProps({ className: "dropzone" })}
        className="mx-auto w-full max-w-[500px] h-full border border-dashed bg-background rounded-md grid place-content-center place-items-center"
      >
        <Svgs.cloud_upload className="w-10 h-10" />
        <h6 className="mt-4 font-inter font-bold not-italic">Choose a file or drag & drop it here.</h6>

        <p className="text-[#85807C] text-sm">JPEG, and PNG formats, up to 1MB.</p>
        <input {...getInputProps()} />
        <Button variant="outline" className="mt-4">
          <File />
          Browse File
        </Button>
      </div>
    </div>
  )
}

const OldFileDisplay = ({ oldFileUrl }: { oldFileUrl: string }) => (
  <div className="w-full">
    <img className="max-w-lg mx-auto" src={oldFileUrl} alt="Family Photo" />
  </div>
)

export const FamilyPhotoPage = () => {
  useDocumentTitle(["Family Photo"])
  const supabase = useSupabaseClient<Database>()
  const application_id = useCurrentApplicationIdSlow()
  const navigate = useNavigate()
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)

  const [newFileUrl, setNewFileUrl] = useState<string | null>(null)
  const [oldFileUrl, setOldFileUrl] = useState<string | null>(null)

  const upload = useCallback(async () => {
    if (!newFileUrl || !croppedAreaPixels) {
      toast.error("Image crop not found")
      return
    }
    const { blob } = await getCroppedImg(newFileUrl, croppedAreaPixels, 0)

    if (!blob) {
      toast.error("Image failed to crop")
      return
    }
    await supabase.storage.from("family_photo").upload(`${application_id}.jpg`, blob, {
      contentType: "image/jpeg",
      upsert: true,
    })
    setOldFileUrl(newFileUrl)
    setNewFileUrl(null)
  }, [application_id, croppedAreaPixels, newFileUrl, supabase.storage])

  const clear = useCallback(async () => {
    const { error } = await supabase.storage.from("family_photo").remove([`${application_id}.jpg`])

    if (error) {
      toast.error(error.message)
      return
    }
    setOldFileUrl(null)
    setNewFileUrl(null)
  }, [application_id, supabase.storage])

  useEffect(() => {
    if (application_id)
      supabase.storage
        .from("family_photo")
        .createSignedUrl(`${application_id}.jpg`, 60 * 60)
        .then(({ data }) => {
          if (data) {
            setOldFileUrl(data.signedUrl)
          }
        })
  }, [application_id, supabase.storage])

  return (
    <>
      <Template className="space-y-4" slug="apply/_/photo#1" />
      <div className="flex flex-col items-end space-y-2">
        <div className="space-x-2">
          {(oldFileUrl || newFileUrl) && (
            <Button variant="destructive" onClick={clear}>
              <XCircle />
              Clear
            </Button>
          )}

          <Button variant="white" onClick={upload}>
            <Save />
            Save
          </Button>
        </div>
        {(() => {
          if (newFileUrl) {
            return <MyImageCropper imageUrl={newFileUrl} setCroppedAreaPixels={setCroppedAreaPixels} />
          } else if (oldFileUrl) {
            return <OldFileDisplay oldFileUrl={oldFileUrl} />
          } else {
            return <MyFileDropper setFileUrl={setNewFileUrl} />
          }
        })()}
        <ActionContainer>
          <Button
            onClick={() => {
              navigate(`/apply/${application_id}/contact`)
            }}
          >
            <ArrowLeft className="w-5 h-5" />
            Previous Page
          </Button>
          <Button
            onClick={() => {
              upload().then(() => {
                navigate(`/apply/${application_id}/availability`)
              })
            }}
          >
            <Save />
            Save & Continue
          </Button>
        </ActionContainer>
      </div>
    </>
  )
}

export default FamilyPhotoPage
