import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { Database } from "@/lib/database.types.ts"

export function useDocumentTitle(crumbs: string[]): void {
  const urlApplicationId = useParams().application_id
  const supabase = useSupabaseClient<Database>()

  useEffect(() => {
    if (urlApplicationId && urlApplicationId !== "_") {
      supabase
        .from("application")
        .select("name")
        .eq("id", urlApplicationId)
        .single()
        .then(({ data }) => {
          document.title = [data?.name, ...crumbs, "Nauvoo Pageant"].join(" | ")
        })
    } else {
      document.title = [...crumbs, "Nauvoo Pageant"].join(" | ")
    }
    return () => {
      document.title = "Nauvoo Pageant"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...crumbs, urlApplicationId, supabase])
}
