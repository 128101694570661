import { Button } from "@/components/ui/button";
import { Database, Enums, Tables } from "@/lib/database.types";
import PageLayout from "@/src/layouts/PageLayout";
import MealGroupFieldset from "@/src/modules/order/meals/MealGroupFieldset";
import { calculateAge } from "@/src/utils/calculateAge";
import useCurrentApplicationIdSlow from "@/src/utils/useCurrentApplicationIdSlow.ts";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

interface Meal {
  id: string;
  name: string;
  key: Tables<"meal_price">["meal_type"];
}

interface MealDate {
  id: string;
  date: string;
  meals: Meal[];
}

interface MealGroup {
  id: string;
  title: string;
  mealDates: MealDate[];
}

type MealPriceGrouped = {
  [key in "lunch" | "breakfast" | "dinner"]: {
    [key in "min_age_incl" | "max_age_incl"]?: { age: number; price: number };
  };
};

const OrderMealsPage = () => {
  const supabase = useSupabaseClient<Database>();
  const application_id = useCurrentApplicationIdSlow();

  const [mealPrice, setMealPrice] = useState<
    Record<
      string,
      Record<
        string,
        Record<
          string,
          { applicant_id: string[]; meal_type: Enums<"meal_type"> }
        >
      >
    >
  >({});
  const [applicants, setApplicants] = useState<Tables<"applicant">[]>();
  const [mealPrices, setMealPrices] = useState<Tables<"meal_price">[]>();
  const [mealOrders, setMealOrders] = useState<Tables<"meal_order">[]>();

  useEffect(() => {
    const fetchMeals = async () => {
      const [
        { data: applicantData },
        { data: mealPrices },
        { data: mealOrders },
      ] = await Promise.all([
        supabase
          .from("applicant")
          .select("*")
          .eq("application_id", application_id!),
        supabase.from("meal_price").select("*"),
        supabase.from("meal_order").select("*"),
      ]);

      if (mealPrices) setMealPrices(mealPrices);
      if (applicantData) setApplicants(applicantData);
      if (mealOrders) setMealOrders(mealOrders);
    };

    fetchMeals();
  }, [supabase, application_id]);

  const alreadyOrderedMeals = useMemo(() => {
    return mealOrders?.reduce((acc, mealOrder) => {
      if (!acc[mealOrder.date]) {
        acc[mealOrder.date] = {};
      }

      if (!acc[mealOrder.date][mealOrder.meal_type]) {
        acc[mealOrder.date][mealOrder.meal_type] = [];
      }

      acc[mealOrder.date][mealOrder.meal_type].push(mealOrder.applicant_id);

      return acc;
    }, {} as Record<string, Record<string, string[]>>);
  }, [mealOrders]);

  const mealPricesGrouped = useMemo(() => {
    return mealPrices?.reduce((acc, mealPrice) => {
      const mealType = mealPrice.meal_type!;
      const ageGroup = mealPrice.max_age_incl ? "max_age_incl" : "min_age_incl";

      if (!acc[mealType]) {
        acc[mealType] = {};
      }

      acc[mealType][ageGroup] = {
        age: mealPrice[ageGroup]!,
        price: mealPrice.price!,
      };

      return acc;
    }, {} as MealPriceGrouped);
  }, [mealPrices]);

  const getMealPrice = useMemo(
    () => (mealType: Meal["key"], age: number) => {
      const mealGroup = mealPricesGrouped?.[mealType!];
      if (!mealGroup) return 0;

      return age <= mealGroup.max_age_incl?.age!
        ? mealGroup.max_age_incl?.price!
        : mealGroup.min_age_incl?.price!;
    },
    [mealPricesGrouped]
  );

  const totalPrice = useMemo(() => {
    return Object.entries(mealPrice).reduce((acc, [mealGroup, mealDate]) => {
      return (
        acc +
        Object.entries(mealDate).reduce((acc, [date, meal]) => {
          return (
            acc +
            Object.entries(meal).reduce((acc, [mealId, persons]) => {
              return (
                acc +
                persons.applicant_id?.reduce((acc, personId) => {
                  const person = applicants?.find(
                    (applicant) => applicant.id === personId
                  );
                  const age = calculateAge(person?.birth_date!);

                  const price = getMealPrice(
                    meals
                      .find((mg) => mg.id === mealGroup)!
                      .mealDates.find((md) => md.id === date)!
                      .meals.find((m) => m.id === mealId)!.key,
                    age
                  );
                  return acc + price;
                }, 0)
              );
            }, 0)
          );
        }, 0)
      );
    }, 0);
  }, [mealPrice, applicants, calculateAge, getMealPrice]);

  const handleSelectAll = useCallback(
    (mealGroupId: string, mealDateId: string, mealId: string) => {
      const isAllSelected =
        mealPrice[mealGroupId]?.[mealDateId]?.[mealId]?.applicant_id.length ===
        applicants?.length;

      const applicant_id = isAllSelected
        ? []
        : applicants!.map((applicant) => applicant.id);

      setMealPrice((prev) => ({
        ...prev,
        [mealGroupId]: {
          ...prev?.[mealGroupId],
          [mealDateId]: {
            ...(prev?.[mealGroupId]?.[mealDateId] || {}),
            [mealId]: {
              applicant_id,
              meal_type: meals
                .find((mg) => mg.id === mealGroupId)!
                .mealDates.find((md) => md.id === mealDateId)!
                .meals.find((m) => m.id === mealId)!.key!,
            },
          },
        },
      }));
    },
    [mealPrice, applicants]
  );

  const handleSelectPerson = useCallback(
    (
      mealGroupId: string,
      mealDateId: string,
      mealId: string,
      person: string
    ) => {
      const isSelected =
        mealPrice[mealGroupId]?.[mealDateId]?.[mealId]?.applicant_id.includes(
          person
        );

      setMealPrice((prev) => ({
        ...prev,
        [mealGroupId]: {
          ...prev?.[mealGroupId],
          [mealDateId]: {
            ...(prev?.[mealGroupId]?.[mealDateId] || {}),
            [mealId]: {
              applicant_id: isSelected
                ? prev?.[mealGroupId]?.[mealDateId]?.[
                    mealId
                  ]?.applicant_id.filter((p) => p !== person)
                : [
                    ...(prev?.[mealGroupId]?.[mealDateId]?.[mealId]
                      ?.applicant_id || []),
                    person,
                  ],
              meal_type: meals
                .find((mg) => mg.id === mealGroupId)!
                .mealDates.find((md) => md.id === mealDateId)!
                .meals.find((m) => m.id === mealId)!.key!,
            },
          },
        },
      }));
    },
    [mealPrice]
  );

  async function handleOrderMeals() {
    let mealsToOrder = Object.entries(mealPrice).reduce(
      (acc, [mealGroupId, mealDate]) => {
        return [
          ...acc,
          ...Object.entries(mealDate).reduce(
            (acc, [date, meal]) => [
              ...acc,
              ...Object.entries(meal).reduce(
                (acc, [_, persons]) => [
                  ...acc,
                  ...persons.applicant_id.map((person) => ({
                    date: meals
                      .find((mg) => mg.id === mealGroupId)!
                      .mealDates.find((md) => md.id === date)!.date,
                    meal_type: persons.meal_type,
                    applicant_id: person,
                  })),
                ],
                [] as {
                  date: string;
                  meal_type: Enums<"meal_type">;
                  applicant_id: string;
                }[]
              ),
            ],
            [] as {
              date: string;
              meal_type: Enums<"meal_type">;
              applicant_id: string;
            }[]
          ),
        ];
      },
      [] as {
        date: string;
        meal_type: Enums<"meal_type">;
        applicant_id: string;
      }[]
    );

    // filter out meals that are already ordered

    mealsToOrder = mealsToOrder.filter((meal) => {
      return !mealOrders?.some(
        (mealOrder) =>
          dayjs(mealOrder.date).format("DD/MM/YYYY") ===
            dayjs(meal.date).format("DD/MM/YYYY") &&
          mealOrder.meal_type === meal.meal_type &&
          mealOrder.applicant_id === meal.applicant_id
      );
    });

    const { error } = await supabase.from("meal_order").insert(mealsToOrder);

    if (error) return toast.error(error.message);

    toast.success("Meals ordered successfully");
  }

  console.log(alreadyOrderedMeals);

  return (
    <PageLayout className="p-4 space-y-10 pb-20">
      {meals.map((mealGroup) => (
        <MealGroupFieldset
          key={mealGroup.id}
          mealGroup={mealGroup}
          applicants={applicants}
          mealPrice={mealPrice}
          handleSelectAll={handleSelectAll}
          handleSelectPerson={handleSelectPerson}
          calculateAge={calculateAge}
          getMealPrice={getMealPrice}
          alreadyOrderedMeals={alreadyOrderedMeals}
        />
      ))}

      <div className="border-t fixed left-0 bottom-0 p-2 bg-background w-full flex items-center justify-between">
        <h4 className="font-inter not-italic">
          Total Price: ${totalPrice.toFixed(2)}
        </h4>

        <Button onClick={handleOrderMeals}>Buy Now</Button>
      </div>
    </PageLayout>
  );
};

export default OrderMealsPage;

const meals: MealGroup[] = [
  {
    id: "a",
    title:
      "Arriving Casts: Entire Time Costuming, Set-up Work Crew, Red Costuming",
    mealDates: [
      {
        id: "a-1",
        date: "06/21/2024",
        meals: [
          { id: "a-1-1", key: "lunch", name: "Lunch" },
          { id: "a-1-2", key: "dinner", name: "Dinner" },
        ],
      },
      {
        id: "a-2",
        date: "06/22/2024",
        meals: [
          { id: "a-2-1", key: "lunch", name: "Lunch" },
          { id: "a-2-2", key: "dinner", name: "Dinner" },
        ],
      },
      {
        id: "a-3",
        date: "06/23/2024",
        meals: [
          { id: "a-3-1", key: "lunch", name: "Lunch" },
          { id: "a-3-2", key: "dinner", name: "Dinner" },
        ],
      },
    ],
  },
  {
    id: "b",
    title: "Arriving Casts: Entire Time Country Fair",
    mealDates: [
      {
        id: "b-1",
        date: "06/24/2024",
        meals: [
          { id: "b-1-1", key: "lunch", name: "Lunch" },
          { id: "b-1-2", key: "dinner", name: "Dinner" },
        ],
      },
      {
        id: "b-2",
        date: "06/25/2024",
        meals: [
          { id: "b-2-1", key: "lunch", name: "Lunch" },
          { id: "b-2-2", key: "dinner", name: "Dinner" },
        ],
      },
    ],
  },
  {
    id: "c",
    title: "Arriving Casts: Entire Time Medical, Entire Time Security",
    mealDates: [
      {
        id: "c-1",
        date: "06/26/2024",
        meals: [
          { id: "c-1-1", key: "lunch", name: "Lunch" },
          { id: "c-1-2", key: "dinner", name: "Dinner" },
        ],
      },
      {
        id: "c-2",
        date: "06/27/2024",
        meals: [
          { id: "c-2-1", key: "lunch", name: "Lunch" },
          { id: "c-2-2", key: "dinner", name: "Dinner" },
        ],
      },
      {
        id: "c-3",
        date: "06/28/2024",
        meals: [
          { id: "c-3-1", key: "lunch", name: "Lunch" },
          { id: "c-3-2", key: "dinner", name: "Dinner" },
        ],
      },
      {
        id: "c-4",
        date: "06/29/2024",
        meals: [
          { id: "c-4-1", key: "lunch", name: "Lunch" },
          { id: "c-4-2", key: "dinner", name: "Dinner" },
        ],
      },
    ],
  },
];
