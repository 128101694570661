import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input/index"
import { Database } from "@/lib/database.types"
import { DataTable } from "@/src/components/DataTable"
import { useAppStore } from "@/src/store"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { QueryData } from "@supabase/supabase-js"
import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import { Loader2, SearchIcon } from "lucide-react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PageLayout from "../../layouts/PageLayout"

const QUERY_STRING = "*, application_availability (preference_order, group_cast ( description ))"

const NOT_EXECUTED = () =>
  useSupabaseClient<Database>().from("application_submitted_order").select(QUERY_STRING).single()

type ApplicationType = QueryData<ReturnType<typeof NOT_EXECUTED>> // & { row_num: number | null }

const CastingList = () => {
  const supabase = useSupabaseClient<Database>()

  const isLeadership = useAppStore((s) => s.isLeadership)

  const [searchQuery, setSearchQuery] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [applications, setApplications] = useState<ApplicationType[]>([])
  useEffect(() => {
    async function fetchApplicants() {
      const [{ error: submittedError, data: submittedData }, { error: unsubmittedError, data: unsubmittedData }] =
        await Promise.all([
          supabase.from("application_submitted_order").select(QUERY_STRING),
          supabase.from("unsubmitted_application_name").select(QUERY_STRING),
        ])
      if (submittedError) {
        console.error("Error fetching application", submittedError)
      }
      if (unsubmittedError) {
        console.error("Error fetching application", unsubmittedError)
      }

      if (!submittedData || !unsubmittedData) {
        return
      }
      for (const d of submittedData) {
        if (d.application_availability.length) {
          console.log(d)
          break
        }
      }

      setApplications([
        ...unsubmittedData.map((d) => ({ ...d, name: d.name || d.generated_name, row_num: null })),
        ...submittedData,
      ])
      // setApplications([ ...unsubmittedData.map(d => ({ ...d, row_num: null })), ...submittedData,])

      setIsLoading(false)
    }

    fetchApplicants()
  }, [supabase])

  const filteredApplications = applications.filter((application) =>
    Object.values(application).some((value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase())),
  )
  const columns: ColumnDef<ApplicationType>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
    },
    {
      accessorKey: "row_num",
      header: "Submitted Order",
      cell: ({ row }) => (
        <span className="block w-20">{row.getValue("row_num") ? row.getValue("row_num") : "unsubmitted"}</span>
      ),
    },
    {
      accessorKey: "submitted_checkbox",
      header: "",
      cell: ({ row }) => {
        if (row.getValue("submitted_at")) row.className = "!bg-green-100 !hover:bg-green-100/80"

        return <Checkbox checked={!!row.getValue("submitted_at")} onCheckedChange={() => {}} aria-label="Select row" />
      },
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "application_availability",
      header: "Preferred Cast",
      cell: ({ row }) => (
        <ol>
          {row
            .getValue<ApplicationType["application_availability"]>("application_availability")
            .filter((a) => a.group_cast != null)
            .sort((a, b) => a.preference_order - b.preference_order)
            .map((a) => (
              <li key={a.group_cast!.description}>
                {a.preference_order}. {a.group_cast!.description}
              </li>
            ))}
        </ol>
      ),
    },
    {
      accessorKey: "started_at",
      header: "Started At",
      cell: ({ row }) =>
        row.getValue("started_at") && <span>{format(new Date(row.getValue("started_at")), "dd/MM/yyyy hh:mm a")}</span>,
    },
    {
      accessorKey: "submitted_at",
      header: "Submitted At",
      cell: ({ row }) => (
        <span>
          {row.getValue("submitted_at")
            ? format(new Date(row.getValue("submitted_at")), "dd/MM/yyyy hh:mm a")
            : "unsubmitted"}
        </span>
      ),
    },
    {
      accessorKey: "action",
      header: "",
      cell: ({ row }) =>
        isLeadership && (
          <Button asChild>
            <Link to={`/apply/${row.original.id}/applicants`}>View</Link>
          </Button>
        ),
    },
  ]

  return (
    <PageLayout className="p-4 gap-8" showFullLogo>
      {isLoading ? (
        <div className="flex-1 grid place-content-center">
          <Loader2 className="w-6 h-6 animate-spin" />
        </div>
      ) : (
        <DataTable
          TopActionButtons={() => (
            <div className="relative flex items-center">
              <SearchIcon className="ml-2 absolute w-5 h-5 text-[#85807C]" />
              <Input
                placeholder="Search"
                className="h-9 bg-white pl-10"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          )}
          columns={columns}
          data={filteredApplications}
          hidePagination
        />
      )}
    </PageLayout>
  )
}

export default CastingList
