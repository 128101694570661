import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function range(stop: number): number[]
export function range(start: number, stop: number): number[]
export function range(start: number, stop: number, step: number): number[]
export function range(startOrStop: number, stop?: number, step?: number): number[] {
  let _start: number
  if (stop == undefined) {
    _start = 0
    stop = startOrStop
    step = 1
  } else if (step == undefined) {
    _start = startOrStop
    step = 1
  } else {
    _start = startOrStop
  }
  return Array.from({ length: (stop - _start) / step }, (_, i) => _start + i * step)
}
