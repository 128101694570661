const TestPage = () => {
  return (
    <button
      onClick={() => {
        throw Error("Hi sentry.io")
      }}
    >
      Break the world
    </button>
  )
}

export default TestPage
