import { Button } from "@/components/ui/button";
import { Tables } from "@/lib/database.types";
import dayjs from "dayjs";
import { memo } from "react";

interface Meal {
  id: string;
  name: string;
  key: Tables<"meal_price">["meal_type"];
}

interface MealDate {
  id: string;
  date: string;
  meals: Meal[];
}

interface MealGroup {
  id: string;
  title: string;
  mealDates: MealDate[];
}

type Props = {
  mealGroup: MealGroup;
  applicants?: Tables<"applicant">[];
  mealPrice: Record<
    string,
    Record<
      string,
      Record<string, { applicant_id: string[]; meal_type: string }>
    >
  >;
  handleSelectAll: (
    mealGroupId: string,
    mealDateId: string,
    mealId: string
  ) => void;
  handleSelectPerson: (
    mealGroupId: string,
    mealDateId: string,
    mealId: string,
    person: string
  ) => void;
  calculateAge: (birthDate: string) => number;
  getMealPrice: (mealType: Meal["key"], age: number) => number;
  alreadyOrderedMeals: Record<string, Record<string, string[]>> | undefined;
};

const MealGroupFieldset = ({
  mealGroup,
  applicants,
  mealPrice,
  handleSelectAll,
  handleSelectPerson,
  calculateAge,
  getMealPrice,
  alreadyOrderedMeals,
}: Props) => {
  return (
    <fieldset className="border rounded-lg">
      <legend className="px-1 mx-4 text-2xl font-bold">
        {mealGroup.title}
      </legend>

      <div className="divide-y">
        {mealGroup.mealDates.map((mealDate: MealDate) => (
          <div key={mealDate.id} className="p-4 grid grid-cols-2 gap-2">
            <h5 className="col-span-full not-italic font-semibold font-inter">
              {dayjs(mealDate.date).format("dddd DD MMMM, YYYY")}
            </h5>

            {mealDate.meals.map((meal) => {
              const totalMealPrice =
                (
                  mealPrice?.[mealGroup.id]?.[mealDate.id]?.[meal.id] || []
                ).applicant_id?.reduce((acc, personId) => {
                  const person = applicants?.find(
                    (applicant) => applicant.id === personId
                  );
                  const age = calculateAge(person?.birth_date!);
                  return acc + getMealPrice(meal.key!, age);
                }, 0) || 0;

              const isAllSelected = applicants?.every((person) =>
                mealPrice?.[mealGroup.id]?.[mealDate.id]?.[
                  meal.id
                ]?.applicant_id?.includes(person.id)
              );

              return (
                <div key={meal.id} className="space-y-2">
                  <h6 className="not-italic font-inter font-medium">
                    {meal.name} - Price ${totalMealPrice?.toFixed(2)}
                  </h6>

                  <div className="flex flex-wrap items-center gap-4">
                    <Button
                      variant={isAllSelected ? "success" : "outline"}
                      className="flex justify-between"
                      onClick={() =>
                        handleSelectAll(mealGroup.id, mealDate.id, meal.id)
                      }
                      disabled={
                        alreadyOrderedMeals?.[
                          dayjs(mealDate.date).format("YYYY-MM-DD")
                        ]?.[meal.key!]?.length === applicants?.length
                      }
                    >
                      Select All
                    </Button>
                    {":"}
                    {applicants?.map((person, k) => {
                      const isAlreadyOrdered = alreadyOrderedMeals?.[
                        dayjs(mealDate.date).format("YYYY-MM-DD")
                      ]?.[meal.key!]?.includes(person.id);

                      return (
                        <Button
                          key={k}
                          variant={
                            !isAlreadyOrdered &&
                            mealPrice?.[mealGroup.id]?.[mealDate.id]?.[
                              meal.id
                            ]?.applicant_id.includes(person.id)
                              ? "success"
                              : "outline"
                          }
                          className="flex justify-between"
                          onClick={() =>
                            handleSelectPerson(
                              mealGroup.id,
                              mealDate.id,
                              meal.id,
                              person.id
                            )
                          }
                          disabled={isAlreadyOrdered}
                        >
                          {person.given_name}
                        </Button>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default memo(MealGroupFieldset);
