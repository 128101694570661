import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import { useEffect, useState } from "react"

export default function useCurrentApplicationIdSlow() {
  const fromApplication = useFromApplication()
  const [application_id, setApplicationId] = useState<string | null>(null)
  useEffect(() => {
    fromApplication.select("id").single().then((result) => setApplicationId(result.data?.id ?? null))
  }, [fromApplication])

  return application_id;
}
