import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { TextareaFormField } from "@/components/ui/textarea/textarea-form-field"
import { zodResolver } from "@hookform/resolvers/zod"
import { ArrowLeft, Save } from "lucide-react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import { z } from "zod"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import Template from "@/src/components/Template.tsx"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { ActionContainer } from "./Constants"

const additionalDetailsFormSchema = z.object({
  description: z.string().max(1000, "Description must be less than 1000 characters").trim(),
})

const AdditionalDetailsPage = () => {
  useDocumentTitle(["Additional Details"])
  const params = useParams()
  const fromApplication = useFromApplication()
  const navigate = useNavigate()
  const additionalDetailsForm = useForm<z.infer<typeof additionalDetailsFormSchema>>({
    resolver: zodResolver(additionalDetailsFormSchema),
    defaultValues: {
      description: "",
    },
  })

  useEffect(() => {
    async function fetchDescription() {
      //do nothing if there is something in the textbox
      if (additionalDetailsForm.getValues("description")) {
        return
      }
      const { data, error } = await fromApplication.select("description").single()

      if (error) {
        console.error("Error fetching Application", error)
        return
      }
      const description = data?.description ?? ""
      //if data.description is defined and form.description is falsey, load data
      if (description) console.log("resetting form")
      additionalDetailsForm.reset({
        description,
      })
    }
    fetchDescription()
  }, [fromApplication, additionalDetailsForm])

  async function handleUpdateDescription(values: z.infer<typeof additionalDetailsFormSchema>) {
    console.log("values", values)

    const x = await fromApplication.update({
      description: values.description,
    })

    const { error } = x
    console.log("update", x)
    if (error) return toast.error(error.message)

    additionalDetailsForm.reset(values)
  }

  return (
    <>
      <div className="space-y-4">
        <Template slug={"apply/_/details#1"} />
      </div>

      <Form {...additionalDetailsForm}>
        <form
          onSubmit={additionalDetailsForm.handleSubmit(handleUpdateDescription)}
          className="flex flex-col items-end space-y-2"
        >
          <div className="text-right">
            <Button type="submit" variant="white">
              <Save />
              Save
            </Button>
          </div>

          <div className="w-full">
            <label htmlFor="description">
              <Template slug="apply/_/details#2" className="text-sm" />
            </label>

            <TextareaFormField name="description" id="description" rows={6} placeholder="Describe here..." />

            <span className="text-sm font-semibold">
              {additionalDetailsForm.watch("description")?.length || 0}/1000
            </span>
          </div>
          <ActionContainer>
            <Button
              onClick={() => {
                navigate(`/apply/${params.application_id}/availability`)
              }}
            >
              <ArrowLeft className="w-5 h-5" />
              Previous Page
            </Button>
            <Button
              onClick={additionalDetailsForm.handleSubmit((value) =>
                handleUpdateDescription(value).then(() => {
                  navigate(`/apply/${params.application_id}/instruments`)
                }),
              )}
            >
              <Save />
              Save & Continue
            </Button>
          </ActionContainer>
        </form>
      </Form>
    </>
  )
}

export default AdditionalDetailsPage
