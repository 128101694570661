import { Enums } from "@/lib/database.types.ts"
import { useSupabaseClient } from "@supabase/auth-helpers-react"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"

export const RequirePermission = ({ anyOf }: { anyOf: Enums<"permission_role">[] }) => {
  const supabaseClient = useSupabaseClient()
  const navigate = useNavigate()
  useEffect(() => {
    async function f() {
      const { data: userPermissions } = await supabaseClient.rpc("permissions_list")
      console.log("userPermissions", userPermissions)
      const userPermissionsSet = new Set<Enums<"permission_role">>(userPermissions)
      if (!anyOf.some((x) => userPermissionsSet.has(x))) {
        navigate("/")
      }
    }
    f()
  }, [anyOf, navigate, supabaseClient])

  return <Outlet />
}
