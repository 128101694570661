import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { InputFormField } from "@/components/ui/input/input-form-field";
import { TextareaFormField } from "@/components/ui/textarea/textarea-form-field";
import { Database } from "@/lib/database.types";
import WelcomeToNauvooPageantry from "@/src/components/WelcomeToNauvooPageantry";
import { Svgs } from "@/src/components/svgs";
import PageLayout from "@/src/layouts/PageLayout";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { ChevronLeft, Plus, Search, XCircle } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import useFileUpload from "react-use-file-upload";
import { toast } from "sonner";
import { z } from "zod";

const memorabiliaFormSchema = z.object({
  title: z.string(),
  notes: z.string(),
  order: z.number(),
  prices: z.array(
    z.object({
      size: z.string(),
      price: z.number().optional(),
    })
  ),
});

const CreateOrderMemorabiliaPage = () => {
  const supabase = useSupabaseClient<Database>();

  const { files, handleDragDropEvent, setFiles, clearAllFiles } =
    useFileUpload();

  const inputFileUploadRef = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState("");

  const memorabiliaForm = useForm<z.infer<typeof memorabiliaFormSchema>>({
    resolver: zodResolver(memorabiliaFormSchema),
    defaultValues: {
      title: "",
      prices: [
        {
          size: "",
          price: undefined,
        },
        {
          size: "",
          price: undefined,
        },
      ],
    },
  });

  const { fields, remove, insert } = useFieldArray({
    control: memorabiliaForm.control, // control props comes from useForm (optional: if you are using FormContext)
    name: "prices",
  });

  const file = files?.[0];

  useEffect(() => {
    setFileUrl(file ? URL.createObjectURL(file) : "");
  }, [file]);

  const handleMemorabiliaFormSubmit = async (
    values: z.infer<typeof memorabiliaFormSchema>
  ) => {
    let filePath = "";

    if (files?.[0]) {
      const file = files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = `${values.title}.${fileExt}`;
      filePath = `souvenirs/${fileName}`;

      const { data, error: uploadError } = await supabase.storage
        .from("souvenir_photo")
        .upload(filePath, file);

      if (uploadError) return toast.error("Failed to upload file");

      filePath = data?.path!;
    }

    const { data, error: souvenirError } = await supabase
      .from("souvenir")
      .insert({
        name: values.title,
        notes: values.notes,
        order: values.order,
        image_path: filePath,
      })
      .select("id")
      .maybeSingle();

    if (souvenirError) return toast.error("Failed to create souvenir");

    const souvenirSizes = values.prices.map(({ size, price }) => ({
      size,
      price: price!,
      souvenir_id: data?.id!,
    }));

    const { error } = await supabase
      .from("souvenir_size")
      .insert(souvenirSizes);

    if (error) return toast.error("Failed to create souvenir sizes");

    toast.success("Souvenir created successfully");

    handleClearAllFiles();
    memorabiliaForm.reset();
  };

  function handleClearAllFiles() {
    setFileUrl("");
    clearAllFiles();
  }

  return (
    <PageLayout className="p-4 space-y-4 container">
      <WelcomeToNauvooPageantry />

      <Button
        variant="white"
        className="w-fit"
        onClick={() => {
          window.history.back();
        }}
      >
        <ChevronLeft className="w-5 h-5" />
        Back
      </Button>

      <Form {...memorabiliaForm}>
        <form
          className="space-y-4"
          onSubmit={memorabiliaForm.handleSubmit(handleMemorabiliaFormSubmit)}
        >
          <div className="w-full bg-primary-100 border rounded-sm p-6 space-y-4">
            <InputFormField
              label="Title"
              name="title"
              description="Please share the name of the product."
              className="bg-white border-gray-300"
              placeholder="Type the title of the product here."
              required
            />

            <TextareaFormField
              label="Notes"
              name="notes"
              className="bg-white border-gray-300"
              placeholder="Type your notes here."
              required
            />

            <InputFormField
              label="Order"
              name="order"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (isNaN(value)) return;
                memorabiliaForm.setValue("order", value);
              }}
              type="number"
              className="bg-white border-gray-300"
              placeholder="Type your order here."
              required
            />

            {fileUrl ? (
              <div className="p-8 mx-auto w-full h-full border border-dashed border-gray-300 bg-white rounded-md grid place-content-center place-items-center gap-4">
                <img
                  className="max-w-lg"
                  src={fileUrl}
                  alt="Family Photography"
                />

                <Button variant="destructive" onClick={handleClearAllFiles}>
                  <XCircle />
                  Clear
                </Button>
              </div>
            ) : (
              <div
                onDragEnter={(e) => handleDragDropEvent(e as any)}
                onDragOver={(e) => handleDragDropEvent(e as any)}
                onDrop={(e) => {
                  handleDragDropEvent(e as any);
                  setFiles(e as any);
                }}
                onClick={() => inputFileUploadRef?.current?.click()}
                className="p-8 mx-auto w-full h-full border border-dashed border-gray-300 bg-white rounded-md grid place-content-center place-items-center cursor-pointer"
              >
                <Svgs.cloud_upload className="w-10 h-10" />

                <h6 className="mt-4 font-inter not-italic">
                  <strong>Click to upload</strong> or drag and drop
                </h6>

                <strong className="text-sm text-gray-500">
                  Max. File Size: 30MB
                </strong>

                <Button className="mt-4" type="button">
                  <Search className="w-5 h-5" />
                  Browse File
                </Button>

                <input
                  ref={inputFileUploadRef}
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => {
                    setFiles(e as any);
                  }}
                />
              </div>
            )}

            <div className="flex flex-col gap-2">
              <p className="font-semibold">Size & Price</p>

              <div className="flex flex-col gap-2">
                {fields.map((field, i) => (
                  <div key={field.id} className="flex items-center">
                    <InputFormField
                      containerClassName="w-40"
                      className="h-10 rounded-r-none bg-gray-100 border-gray-300 placeholder:text-gray-400"
                      name={`prices.${i}.size`}
                      placeholder="Type Size"
                      required
                    />

                    {/* <span className="bg-white h-10 flex-1 px-2 border-y border-r border-gray-300 grid place-content-center text-center">
                      {"$"}
                    </span> */}

                    <InputFormField
                      className="h-10 border-x-0 rounded-l-none rounded-r-none bg-white border-gray-300"
                      name={`prices.${i}.price`}
                      type="number"
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);

                        memorabiliaForm.setValue(
                          `prices.${i}.price`,
                          isNaN(value) ? 0 : value
                        );
                      }}
                      placeholder="Type your price range. $0.00"
                      required
                    />

                    <Button
                      className="h-auto rounded-l-none shadow-none"
                      onClick={() => remove(i)}
                      variant="default"
                      type="button"
                    >
                      <XCircle />
                    </Button>
                  </div>
                ))}

                <button
                  type="button"
                  className="flex items-center gap-1 text-secondary-500 font-medium"
                  onClick={() =>
                    insert(fields.length, { size: "", price: undefined })
                  }
                >
                  <Plus className="w-5 h-5" /> Add more
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <Button disabled={memorabiliaForm.formState.isSubmitting}>
              Save
            </Button>
          </div>
        </form>
      </Form>
    </PageLayout>
  );
};

export default CreateOrderMemorabiliaPage;
