import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { InputFormField } from "@/components/ui/input/input-form-field"
import { zodResolver } from "@hookform/resolvers/zod"
import { ArrowLeft, Loader2, Save } from "lucide-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "sonner"
import { z } from "zod"
import { useFromApplication } from "@/src/hooks/UseFromApplication.ts"
import { useDocumentTitle } from "@/src/hooks/UseDocumentTitle.ts"
import { ActionContainer } from "./Constants"
import Template from "@/src/components/Template.tsx"

const contactInformationFormSchema = z.object({
  address1: z.string().min(1, "Address 1 is required"),
  address2: z.string().optional().nullable(),
  city: z.string().min(1, "City is required"),
  state: z.string().min(1, "State is required"),
  zip: z.string().min(1, "Zip code is required"),
  country: z.string().optional().nullable(),
  notification_cellphones: z.string().min(1, "Phone number is required"),
  notification_emails: z.string().min(1, "Email is required"),
})

const ContactInformationPage = () => {
  useDocumentTitle(["Contact Information"])
  const navigate = useNavigate()
  const urlApplicationId = useParams().application_id
  const fromApplication = useFromApplication()

  const [isLoading, setIsLoading] = useState(true)

  const contactInformationForm = useForm<z.infer<typeof contactInformationFormSchema>>({
    resolver: zodResolver(contactInformationFormSchema),
    defaultValues: {
      address1: "",
      address2: "",
      notification_cellphones: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      notification_emails: "",
    },
  })

  useEffect(() => {
    async function fetchApplication() {
      const { data, error } = await fromApplication
        .select("address1,address2,notification_cellphones,city,state,zip,country,notification_emails")
        .maybeSingle()

      if (error) {
        toast.error("Error fetching Application")
      }

      if (data) {
        contactInformationForm.reset({
          address1: data.address1 ?? "",
          address2: data.address2 ?? "",
          notification_cellphones: (data.notification_cellphones ?? []).join(", "),
          city: data.city ?? "",
          state: data.state ?? "",
          zip: data.zip ?? "",
          country: data.country ?? "",
          notification_emails: (data.notification_emails ?? []).join(", "),
        })
      }

      setIsLoading(false)
    }

    fetchApplication()
  }, [contactInformationForm, fromApplication])

  async function handleContactInformation(
    values: z.infer<typeof contactInformationFormSchema>,
    moveToNextPage = false,
  ) {
    const { error } = await fromApplication.update({
      ...values,
      notification_cellphones: values.notification_cellphones.split(",").map((s) => s.trim()),
      notification_emails: values.notification_emails.split(",").map((s) => s.trim()),
    })

    if (error) {
      toast.error("Error saving contact information")

      return
    }

    if (moveToNextPage) {
      // setCurrentActiveTab("4");}
    }
  }

  return (
    <>
      <Template slug="apply/_/contact#1" />

      {isLoading ? (
        <Loader2 className="animate-spin mx-auto" />
      ) : (
        <Form {...contactInformationForm}>
          <form
            onSubmit={contactInformationForm.handleSubmit((values) => handleContactInformation(values, true))}
            className="grid md:grid-cols-2 lg:grid-cols-4 gap-4"
          >
            <div className="col-span-full text-right">
              <Button variant="white" type="submit">
                <Save />
                Save
              </Button>
            </div>

            <InputFormField
              containerClassName="md:col-span-2 lg:col-span-5"
              label="Address 1"
              name="address1"
              placeholder="1234 Main St"
              required
            />

            <InputFormField label="City" name="city" placeholder="Granger" required />

            <InputFormField label="State/Province" name="state" placeholder="IN" required />

            <InputFormField label="Zip/Postal" name="zip" placeholder="46530" required />

            <InputFormField label="Country" name="country" placeholder="If not USA" />

            <br />

            <InputFormField
              containerClassName="col-span-2"
              label="Phone Numbers, comma separated"
              name="notification_cellphones"
              placeholder={"000-000-0000, 111-111-1111"}
              required
            />

            <InputFormField
              containerClassName="col-span-2"
              label="Email Adresses, comma separated"
              name="notification_emails"
              placeholder={"a@example.com, b@example.com"}
              required
            />

            <div className="col-span-full text-right">
              <ActionContainer>
                <Button
                  onClick={() => {
                    navigate(`/apply/${urlApplicationId}/Participation`)
                  }}
                >
                  <ArrowLeft className="w-5 h-5" />
                  Previous Page
                </Button>
                <Button
                  onClick={contactInformationForm.handleSubmit((values) =>
                    handleContactInformation(values).then(() => {
                      navigate(`/apply/${urlApplicationId}/photo`)
                    }),
                  )}
                >
                  <Save />
                  Save & Continue
                </Button>
              </ActionContainer>
            </div>
          </form>
        </Form>
      )}
    </>
  )
}

export default ContactInformationPage
